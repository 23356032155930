export const getInitials = (username: string): string => {
  let splitted = username.toUpperCase().split(' ');

  if (splitted.length === 2) {
    return `${splitted[0][0]}${splitted[1][0]}`;
  }

  splitted = username.toUpperCase().split('.');

  return splitted.length > 2
    ? `${splitted[0][0]}${splitted[1][0]}`
    : `${splitted[0][0]}${splitted[0][1]}`;
};
