import { FC, useEffect, useRef, useState } from 'react';
import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import {
  SuiteIcon,
  TriangleIcon,
  useClickAway,
  UserIcon
} from 'cj-common-react';
import { SuiteDropdownProps } from './types';
import { getInitials } from './utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useHomeCtx } from 'context/HomeContext';

const SuiteDropdown: FC<SuiteDropdownProps> = ({
  suiteApps,
  showUser = false
}) => {
  const { user } = useAuth0();
  const {
    userMember,
    featureFlags: { useArcadiaStyling }
  } = useHomeCtx();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const menuRef = useRef<HTMLDivElement>(null);
  const userMenuRef = useRef<HTMLDivElement>(null);

  useClickAway(menuRef, () => setIsOpen(false));

  const [currentActive, setCurrentActive] = useState<string>('');
  const [dropdownWidth, setDopdownWidth] = useState<number | string>('100%');

  useEffect(() => {
    if (showUser) {
      setDopdownWidth(userMenuRef?.current?.clientWidth || '100%');
    }
  }, [userMenuRef]);

  return (
    <Flex position='relative' justifyContent='center' ref={menuRef}>
      {showUser ? (
        <Flex
          ref={userMenuRef}
          minW='245px'
          h='52px'
          p='5px 12px 5px 5px'
          direction='column'
          justifyContent='center'
          alignItems='flex-end'
          cursor='pointer'
          backgroundColor={isOpen ? theme.colors?.white : ''}
          onClick={() => setIsOpen((prevState) => !prevState)}
          borderTopRadius='6px'
          borderBottom={
            isOpen && !useArcadiaStyling
              ? `1px solid ${theme.colors?.lightGrey}`
              : 'none'
          }
          data-testid='suite-dropdown--user-menu--container'
        >
          <Flex>
            {!useArcadiaStyling && (
              <Flex
                direction='column'
                data-testid='initials-container'
                justifyContent='center'
                alignItems='flex-end'
                marginRight='4px'
              >
                <Text textStyle='bold-12-14' color={theme.colors?.darkBlue}>
                  {user?.name}
                </Text>
                <Text
                  textStyle='regular-12-14'
                  color={theme.colors?.mediumGrey}
                >
                  {userMember}
                </Text>
              </Flex>
            )}
            <Flex
              position='relative'
              justifyContent='center'
              alignItems='center'
              w={useArcadiaStyling ? '40px' : '42px'}
              h={useArcadiaStyling ? '40px' : '42px'}
              borderRadius={useArcadiaStyling ? '50%' : '4px'}
              overflow={useArcadiaStyling ? 'hidden' : 'unset'}
              backgroundColor={
                useArcadiaStyling ? 'transparent' : theme.colors?.blueOne
              }
              data-testid='suite-dropdown--user-menu--initials'
            >
              {useArcadiaStyling ? (
                <Box
                  borderRadius='50%'
                  p='10px'
                  backgroundColor={
                    isOpen
                      ? theme.colors?.explorerTableHeaderColor
                      : 'transparent'
                  }
                >
                  <UserIcon
                    dataTestId='user-icon'
                    backgroundColor={
                      isOpen
                        ? theme.colors?.explorerTableHeaderColor
                        : theme.colors?.white
                    }
                    color={
                      isOpen
                        ? theme.colors?.darkBlue
                        : theme.colors?.arcadiaMainGrey
                    }
                  />
                </Box>
              ) : (
                <Text textStyle='bold-16-18' color={theme.colors?.white}>
                  {getInitials(user?.name || 'UR.')}
                </Text>
              )}
              {!useArcadiaStyling && (
                <Flex
                  position='absolute'
                  w='12px'
                  h='12px'
                  right='-6px'
                  borderRadius='50%'
                  backgroundColor={theme.colors?.lightGrey}
                  justifyContent='center'
                  alignItems='center'
                  data-testid='suite-dropdown--user-menu--icon-container'
                >
                  <TriangleIcon
                    width={8}
                    height={8}
                    cursor='pointer'
                    margin={isOpen ? '1px 0 0 0' : '0 0 1px 0'}
                    direction={isOpen ? 'up' : 'down'}
                  />
                </Flex>
              )}
            </Flex>
            {useArcadiaStyling && (
              <Flex
                w='12px'
                h='12px'
                alignSelf='center'
                ml='8px'
                justifyContent='center'
                alignItems='center'
                data-testid='suite-dropdown--user-menu--icon-container'
              >
                <TriangleIcon
                  width={10}
                  height={10}
                  cursor='pointer'
                  color={theme.colors?.arcadiaMainGrey}
                  direction={isOpen ? 'up' : 'down'}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex
          width='40px'
          height='40px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          cursor='pointer'
          borderRadius='50%'
          backgroundColor={isOpen ? theme.colors?.tableHeaderBlue : ''}
          onClick={() => setIsOpen((prevState) => !prevState)}
          data-testid='suite-dropdown--menu-icon'
        >
          <SuiteIcon
            color={
              isOpen
                ? theme.colors?.darkBlue
                : useArcadiaStyling
                ? theme.colors?.arcadiaMainGrey
                : theme.colors?.mediumGrey
            }
          />
        </Flex>
      )}
      {isOpen && (
        <Box data-testid='suite-dropdown--menu-container'>
          <Flex
            position='absolute'
            top={showUser ? '56px' : '45px'}
            right={0}
            backgroundColor={theme.colors?.white}
            minW='245px'
            w={useArcadiaStyling ? 'fit-content' : dropdownWidth}
            height='fit-content'
            boxShadow={theme.shadow.xl}
            borderTopRadius={showUser ? '0' : '6px'}
            borderBottomRadius='6px'
            direction='column'
            justifyContent='center'
            alignItems='flex-start'
          >
            {useArcadiaStyling && showUser && (
              <Flex
                p='10px 14.5px'
                gap='10px'
                borderBottom={`1px solid ${theme.colors?.lightGrey}`}
                width='100%'
              >
                <Flex
                  position='relative'
                  justifyContent='center'
                  alignItems='center'
                  w='42px'
                  h='42px'
                  borderRadius='4px'
                  overflow='unset'
                  backgroundColor={theme.colors?.blueOne}
                  data-testid='suite-dropdown--user-menu--initials'
                >
                  <Text textStyle='bold-16-18' color={theme.colors?.white}>
                    {getInitials(user?.name || 'UR.')}
                  </Text>
                </Flex>
                <Flex
                  direction='column'
                  justifyContent='center'
                  alignItems='flex-start'
                  marginRight='4px'
                >
                  <Text textStyle='bold-12-14' color={theme.colors?.darkBlue}>
                    {user?.name}
                  </Text>
                  <Text
                    textStyle='regular-12-14'
                    color={theme.colors?.mediumGrey}
                  >
                    {userMember}
                  </Text>
                </Flex>
              </Flex>
            )}
            {suiteApps.map(
              ({
                name,
                handleClick,
                icon,
                label,
                labelColor = useArcadiaStyling
                  ? theme.colors?.arcadiaMainGrey
                  : theme.colors?.mediumGrey,
                activeBorderColor = theme.colors?.blueOne
              }) => (
                <Flex
                  boxSizing='border-box'
                  width='100%'
                  height='44px'
                  cursor='pointer'
                  key={name}
                  alignItems='center'
                  justifyContent='flex-start'
                  padding='0 10px'
                  borderBottom={`1px solid ${theme.colors?.lightGrey}`}
                  _hover={{
                    backgroundColor: theme.colors?.explorerTableHeaderColor
                  }}
                  role='group'
                  onClick={handleClick}
                  _active={{
                    borderBottom: `1px solid ${activeBorderColor}`,
                    borderLeft: `${
                      useArcadiaStyling ? '4px' : '2px'
                    } solid ${activeBorderColor}`,
                    backgroundColor: 'transparent'
                  }}
                  onMouseDown={() => setCurrentActive(name)}
                  onMouseUp={() => setCurrentActive('')}
                  transition='all 0.3s ease-in-out'
                  data-testid='suite-dropdown--menu-option'
                >
                  <Flex
                    minW='24px'
                    minH='24px'
                    alignItems='center'
                    justifyContent='center'
                  >
                    {icon(currentActive === name)}
                  </Flex>
                  <Text
                    marginLeft='10px'
                    textStyle={
                      useArcadiaStyling
                        ? 'inter-regular-12-14'
                        : 'regular-12-14'
                    }
                    cursor='pointer'
                    color={labelColor}
                    _groupActive={{
                      color: theme.colors?.blueOne,
                      textStyle: useArcadiaStyling
                        ? 'inter-semiBold-12-14'
                        : 'semiBold-12-14'
                    }}
                    data-testid='suite-dropdown--menu-option-text'
                  >
                    {label}
                  </Text>
                </Flex>
              )
            )}
          </Flex>
        </Box>
      )}
    </Flex>
  );
};
export default SuiteDropdown;
