import { FC } from 'react';
import {
  withAuth0,
  withAuthenticationRequired,
  useAuth0
} from '@auth0/auth0-react';
import { HOME_ENVIRONMENT } from 'config/environments/homeEnvironments';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import ProtectedRoutes from './ProtectedRoutes';
import {
  COMMON_PAGES,
  LD_LOCAL_USER_KEY,
  USER_AUTHORIZATION_NAMESPACE_KEY
} from 'config';
import ConfigurationHome from 'components/ConfigurationHome';

const Protected: FC = () => {
  const { user } = useAuth0();
  const authorization = user?.[USER_AUTHORIZATION_NAMESPACE_KEY];

  return (
    <>
      {user && (
        <LDProvider
          clientSideID={HOME_ENVIRONMENT.LAUNCH_DARKLY_CLIENT_ID}
          user={{
            key:
              HOME_ENVIRONMENT.ENVIRONMENT === 'local'
                ? LD_LOCAL_USER_KEY
                : user?.sub,
            custom: {
              memberName: authorization?.member_name || '',
              memberDisplayName: authorization?.member_display_name || ''
            },
            email: user?.email || ''
          }}
          options={{
            hash:
              HOME_ENVIRONMENT.ENVIRONMENT === 'local'
                ? '69b0e6196f6ec543fdd7179c3b6735cf26b3e77165f02c60393ab68f9ed4da5b'
                : authorization?.ldHash
          }}
        >
          <ConfigurationHome useAuth0={useAuth0} environment={HOME_ENVIRONMENT}>
            <ProtectedRoutes user={user} />
          </ConfigurationHome>
        </LDProvider>
      )}
    </>
  );
};

export default withAuthenticationRequired(withAuth0(Protected), {
  onRedirecting: () => COMMON_PAGES.loading
});
