import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useTheme } from '@chakra-ui/react';
import { CJLogoProps } from './types';
import { CJIcon } from 'cj-common-react';

const CJLogo: FC<CJLogoProps> = ({
  showSubtitle,
  subtitleBelow,
  isTablet,
  linkTo,
  cjHome,
  isNavBar
}: CJLogoProps) => {
  const history = useHistory();
  const theme = useTheme();

  const handleClick: () => void = () => {
    if (linkTo) {
      history.push(linkTo);
    } else {
      history.push('/');
    }
  };

  const LogosWidth = cjHome ? 559 : subtitleBelow ? 300 : !isTablet ? 245 : 170;
  const LogosHeight = cjHome ? 110 : subtitleBelow ? 56.5 : 46;
  const LogosColor = cjHome ? theme.colors?.white : '';

  return (
    <Box
      display='flex'
      flexDirection={subtitleBelow ? 'column' : 'row'}
      justifyItems='center'
      alignItems='center'
      cursor='pointer'
      onClick={handleClick}
      data-testid='cj-logo-container-id'
    >
      <CJIcon
        width={isNavBar ? 170 : LogosWidth}
        height={LogosHeight}
        color={LogosColor}
        cursor='pointer'
      />
      {showSubtitle && (
        <Box
          as='label'
          fontSize={subtitleBelow ? '14px' : !isTablet ? '20px' : '12px'}
          lineHeight={subtitleBelow ? '19px' : '27px'}
          margin={subtitleBelow ? '0 20px 0 0' : '0 0 0 5px'}
          position={subtitleBelow ? 'relative' : 'inherit'}
          top={subtitleBelow ? '-10px' : '0'}
          color={theme.colors?.gold}
          data-testid='provider-search-title'
          id='provider-search-title'
        >
          Home
        </Box>
      )}
    </Box>
  );
};

export default CJLogo;
