import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from 'styled-components';
import 'assets/fonts/fonts.css';
import App from './App';
import { HOME_ENVIRONMENT } from 'config/environments/homeEnvironments';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { chakraTheme, styledComponentsTheme } from 'cj-common-react';
import { ChakraProvider } from '@chakra-ui/react';
import { COMMON_PAGES } from 'config';

ReactDOM.render(
  <Auth0Provider
    domain={HOME_ENVIRONMENT.DOMAIN}
    clientId={HOME_ENVIRONMENT.CLIENT_ID}
    redirectUri={window.location.origin}
    audience={HOME_ENVIRONMENT.AUDIENCE}
    onRedirectCallback={(appState) => {
      window.location.href = appState?.returnTo || window.location.origin;
    }}
    appState={{
      returnTo: window.location.pathname + window.location.search
    }}
  >
    <ChakraProvider theme={chakraTheme}>
      <ThemeProvider theme={styledComponentsTheme}>
        <ErrorBoundary ErrorComponent={COMMON_PAGES.error}>
          <Helmet>
            <meta property='og:title' content='CJ Home' />
            <meta property='og:description' content='CJ Home' />
            <meta property='og:image' content='/favicon.ico' />
            <meta name='robots' content='noindex' />
          </Helmet>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </ChakraProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
