import { FC } from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { ReportsGridView } from 'cj-my-reports-ui';

const MyReportsRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}/id/:id`}>
          <ReportsGridView />
        </Route>
      </Switch>
    </>
  );
};

export default MyReportsRoutes;
