import { SuiteAppProps } from '../SuiteDropdown/types';
import {
  CJLogoIcon,
  chakraTheme,
  LaunchDarklyFeatureFlagsProps,
  WebinarIcon,
  DataIcon,
  CommunityIcon,
  ProviderPerformanceIcon,
  ClassicIcon,
  MarketIntelligenceIcon,
  LockIcon,
  LogoutIcon,
  BuildANetworkIcon,
  CareRetentionIcon,
  RiskContractIcon,
  CareAcceleratorIcon
} from 'cj-common-react';
import mixpanel from 'mixpanel-browser';
import { User } from '@auth0/auth0-react';
import { env, HOME_ENVIRONMENT } from 'config/environments/homeEnvironments';
import { History } from 'history';
import { userHasPermission } from 'pages/Home/config';


export const communityURL =
  env === 'prod'
    ? 'https://dataverse.carejourney.com/community'
    : 'https://dataverse-int.carejourney.com/community';

export const webinarsURL = 'https://dc.carejourney.com/member-webinars';

export const getHomeApps = (
  user: User | undefined,
  history: History,
  featureFlags: LaunchDarklyFeatureFlagsProps,
): SuiteAppProps[] => {
  const apps = [
    {
      name: 'home',
      label: featureFlags.showNewBrandUi
        ? 'CareJourney by Arcadia Home'
        : 'CareJourney Home',
      icon: (active) => (
        <CJLogoIcon
          width={23}
          height={19}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('home Clicked', {
          appName: 'home'
        });
        history.push('/home');
      }
    }
  ];

  if (
    featureFlags?.showMarketIntelligenceTile &&
    userHasPermission(user || {}, 'marketIntelligence')
  ) {
    apps.push({
      name: 'marketIntelligence',
      label: 'Market Intelligence',
      icon: (active) => (
        <MarketIntelligenceIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('explorer Clicked', {
          appName: 'explorer'
        });
        history.push('/explorer');
      }
    });
  }

  if (
    featureFlags?.showProviderPerformanceTile &&
    userHasPermission(user || {}, 'providerPerformance')
  ) {
    apps.push({
      name: 'providerPerformance',
      label: 'Provider Performance',
      icon: (active) => (
        <ProviderPerformanceIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('Provider Performance Clicked', {
          appName: 'Provider Performance'
        });
        history.push(
          featureFlags.showNewProviderAdvantage
            ? '/provider-performance-v2'
            : '/provider-performance'
        );
      }
    });
  }

  if (
    !featureFlags?.hideContentForCjTrialMembers &&
    featureFlags?.showBuildANetworkTile &&
    userHasPermission(user || {}, 'buildANetwork')
  ) {
    apps.push({
      name: 'buildANetwork',
      label: 'Network Development',
      icon: (active) => (
        <BuildANetworkIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('Build a Network Clicked', {
          appName: 'Build a Network'
        });
        history.push('/network-development');
      }
    });
  }

  if (
    !featureFlags?.hideContentForCjTrialMembers &&
    featureFlags?.showCareRetentionTile &&
    userHasPermission(user || {}, 'careRetention')
  ) {
    apps.push({
      name: 'careRetention',
      label: 'Care Retention',
      icon: (active) => (
        <CareRetentionIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('Care Retention Clicked', {
          appName: 'Care Retention'
        });
        history.push('/care-retention');
      }
    });
  }

  if (
    featureFlags?.showRiskContractManagementTile &&
    userHasPermission(user || {}, 'riskContractManagement')
  ) {
    apps.push({
      name: 'riskContractManagement',
      label: 'Risk Management',
      icon: (active) => (
        <RiskContractIcon
          variant='outlined'
          height={30}
          width={30}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('Risk Contract Management Clicked', {
          appName: 'Risk Contract Management'
        });
        history.push('/risk-management');
      }
    });
  }

  if (featureFlags?.showCarejourneyClassicTile) {
    apps.push({
      name: 'cjClassic',
      label: 'CareJourney Classic',
      icon: (active) => (
        <ClassicIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('Care Journey Classic App Clicked', {
          appName: 'carejouneyLegacy'
        });
        window.location.href = HOME_ENVIRONMENT.PORTAL_URL;
      }
    });
  }

  if (
    featureFlags.showCareAccelerator &&
    userHasPermission(user || {}, 'careAccelerator')
  ) {
    apps.push({
      name: 'careAccelerator',
      label: 'Patient Pulse',
      icon: (active) => (
        <CareAcceleratorIcon
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          variant='outlined'
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('Care Accelertor App Clicked', {
          appName: 'careAccelerator'
        });
        history.push('/care-accelerator');
      }
    });
  }

  if (featureFlags?.showDataCommonsTiles) {
    apps.push({
      name: 'webinars',
      label: 'Webinars',
      icon: (active) => (
        <WebinarIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('webinars Clicked', {
          appName: 'webinars'
        });
        window.location.href = webinarsURL;
      }
    });
  }

  if (featureFlags?.showCommunityTile) {
    apps.push({
      name: 'community',
      label: 'Community',
      icon: (active) => (
        <CommunityIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('community Clicked', {
          appName: 'community'
        });
        window.location.href = communityURL;
      }
    });
  }

  if (featureFlags?.showDataCommonsTiles) {
    apps.push({
      name: 'dataVerse',
      label: 'Dataverse',
      icon: (active) => (
        <DataIcon
          variant='outlined'
          height={23}
          width={23}
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          cursor='pointer'
        />
      ),
      handleClick: () => {
        mixpanel.track('dataverse Clicked', {
          appName: 'dataverse'
        });
        history.push('/dataverse');
      }
    });
  }

  return apps;
};

export const getUserMenuDropdownItems = (
  user: User | undefined,
  logout: Function,
  setIsChangePasswordModalOpen: Function
): SuiteAppProps[] => {
  const userMenuApss: SuiteAppProps[] = [];
  const isCareJourneyEmployee =
    user?.email && user.email.endsWith('@carejourney.com');

  if (!isCareJourneyEmployee) {
    userMenuApss.push({
      name: 'changePassword',
      label: 'Change Password',
      icon: (active) => (
        <LockIcon
          color={
            active
              ? chakraTheme.colors?.blueOne
              : chakraTheme.colors?.mediumGrey
          }
          width={20}
          height={20}
          cursor='pointer'
        />
      ),
      handleClick: () => setIsChangePasswordModalOpen(true)
    });
  }

  userMenuApss.push({
    name: 'signOut',
    label: 'Sign Out',
    labelColor: chakraTheme.colors?.red,
    activeBorderColor: chakraTheme.colors?.red,
    icon: () => (
      <LogoutIcon
        color={chakraTheme.colors?.red}
        width={20}
        height={20}
        cursor='pointer'
      />
    ),
    handleClick: () => {
      mixpanel.track('Sign out', {
        currentPage: window.location.pathname
      });
      mixpanel.reset();
      window.open(
        'https://dataverse.carejourney.com/_hcms/mem/logout/',
        '_blank'
      );
      logout({
        returnTo: window.location.origin
      });
    }
  });

  return userMenuApss;
};
