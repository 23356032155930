import { Box } from '@chakra-ui/react';
import { Footer } from 'cj-common-react';
import { useHomeCtx } from 'context/HomeContext';
import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
}
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    featureFlags: { showNewBrandUi, useArcadiaStyling }
  } = useHomeCtx();
  return (
    <Box className={useArcadiaStyling ? 'inter-font arcadia-bg' : 'open-font'} >
      {children}
      <Footer
        marginTop='26px'
        useArcadiaStyling={useArcadiaStyling}
        showNewBrand={showNewBrandUi}
      />
    </Box>
  );
};
