export const MAIL_SENT_TEXT =
  'We have just sent you an email to reset your password.';

export const CONFIRM_CHANGE_PASSWORD_TEXT =
  'Are you sure you want to change your password?';

export const CHANGE_PASSWORD_ERROR_TITLE_TEXT =
  'Sorry, we were unable to complete your change password request.';

export const CHANGE_PASSWORD_ERROR_SUBTITLE_TEXT = `If this problem persists, please contact CareJourney's Member Services
team.`;
