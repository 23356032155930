import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { RedirectWithParamsProps } from './types';

const RedirectWithParams: FC<RedirectWithParamsProps> = ({ to }) => {
  const queryParams = window.location.href.split('?')[1];

  return (
    <Redirect
      to={`${to}${queryParams && queryParams ? '?' + queryParams : ''}`}
    />
  );
};

export default RedirectWithParams;
