import { useState } from 'react';
import axios from 'axios';
import { HOME_ENVIRONMENT } from 'config/environments/homeEnvironments';

type usePasswordChangeReturnType = {
  changePassword: () => void;
  mailSent: string;
  error: boolean;
  loading: boolean;
};

const usePasswordChange: (
  userEmail: string | undefined
) => usePasswordChangeReturnType = (userEmail) => {
  const [mailSent, setMailSent] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const changePassword = async (): Promise<void> => {
    setMailSent('');
    setError(false);
    setLoading(true);
    try {
      const response = await axios.request({
        method: 'POST',
        url: `https://${HOME_ENVIRONMENT.DOMAIN}/dbconnections/change_password`,
        headers: { 'content-type': 'application/json' },
        data: {
          client_id: HOME_ENVIRONMENT.CLIENT_ID,
          email: userEmail,
          connection: HOME_ENVIRONMENT.CONNECTION_NAME
        }
      });
      const { status, data } = response;
      if (status >= 400) {
        setError(true);
        console.debug('usePasswordChange - Error', response);
      } else {
        setMailSent(data);
      }
    } catch (e) {
      console.debug('usePasswordChange - Error', e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    changePassword,
    mailSent,
    error,
    loading
  };
};

export default usePasswordChange;
