import { LaunchDarklyFeatureFlagsProps } from 'cj-common-react';
import { isEmpty } from 'lodash';

export const featureFlagsFallback = (
  featureFlags: LaunchDarklyFeatureFlagsProps
): LaunchDarklyFeatureFlagsProps => {
  let updatedFeatureFlags = featureFlags;

  if (isEmpty(updatedFeatureFlags) && localStorage.getItem('featureFlags')) {
    updatedFeatureFlags = JSON.parse(
      localStorage.getItem('featureFlags') as string
    ) as LaunchDarklyFeatureFlagsProps;
  } else {
    localStorage.setItem('featureFlags', JSON.stringify(updatedFeatureFlags));
  }

  return updatedFeatureFlags;
};
