import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import MyReportsRoutes from 'routes/MyReports';
import { ConfigurationExplorer, ExplorerRoutes } from 'cj-explorer-ui';
import { ConfigurationDataverse, DataverseRoutes } from 'cj-dataverse-ui';
import { RMConfiguration, RMRoutes } from 'cj-risk-management-ui';
import { ConfigurationMyReports } from 'cj-my-reports-ui';
import { ConfigurationTableau, TableauRoutes } from 'cj-tableau-ui';

import { createBrowserHistory } from 'history';
import { EXPLORER_ENVIRONMENT } from 'config/environments/explorerEnvironments';
import { RM_ENVIRONMENT } from 'config/environments/riskManagementEnvironment';
import { DV_ENVIRONMENT } from 'config/environments/dataverseEnvironments';
import { MY_REPORTS_ENVIRONMENT } from 'config/environments/myReportsEnvironments';
import { TABLEAU_ENVIRONMENT } from 'config/environments/tableauEnvironments';
import RouteWrapper from 'components/RouteWrapper';
import { hasUserAccess } from 'util/index';
import { ProtectedRoutesProps } from './types';
import { useHomeCtx } from 'context/HomeContext';
import { COMMON_PAGES } from 'config';
import { Home } from 'pages/Home';
import ChangePasswordModal from './ChangePasswordModal';
import useIsLDReady from 'hooks/useIsLDReady';
import RedirectWithParams from './RedirectWithParams';
import { Layout } from 'components/Layout/Layout';
import {
  ConfigurationProviderAdvantage,
  ProviderAdvantageRoutes
} from 'cj-provider-advantage-ui';
import { PP_ENVIRONMENT } from 'config/environments/providerPerformanceEnvironments';
import { RedirectPage } from 'components/RedirectPage/RedirectPage';

export const history = createBrowserHistory();

const ProtectedRoutes: FC<ProtectedRoutesProps> = ({ user }) => {
  const { isChangePasswordModalOpen, featureFlags } =
    useHomeCtx();

  const {
    showMyReports,
    showTableau,
    showNewRiskManagement,
    showNewProviderAdvantage,
    deprecateOtherProfilesAndProviderRoutes
  } = featureFlags;
  const launchDarklyReady = useIsLDReady();
  return (
    <Router history={history}>
      {isChangePasswordModalOpen && (
        <ChangePasswordModal isOpen={isChangePasswordModalOpen} />
      )}
      {!launchDarklyReady ? (
        COMMON_PAGES.loading
      ) : (
        <Layout>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/home' />
            </Route>
            <RouteWrapper exact path='/home'>
              <Home />
            </RouteWrapper>
            <RouteWrapper path='/explorer'>
              {hasUserAccess(user, 'explorer') ? (
                <ConfigurationExplorer
                  environment={EXPLORER_ENVIRONMENT}
                  useAuth0={useAuth0}
                  featureFlags={featureFlags}
                >
                  <ExplorerRoutes />
                </ConfigurationExplorer>
              ) : (
                COMMON_PAGES.unauthorized
              )}
            </RouteWrapper>
            {showMyReports && (
              <RouteWrapper path='/my-reports'>
                {hasUserAccess(user, 'myReports') ? (
                  <ConfigurationMyReports
                    environment={MY_REPORTS_ENVIRONMENT}
                    useAuth0={useAuth0}
                    featureFlags={featureFlags}
                  >
                    <MyReportsRoutes />
                  </ConfigurationMyReports>
                ) : (
                  COMMON_PAGES.unauthorized
                )}
              </RouteWrapper>
            )}
            <RouteWrapper path='/cohort'>
              {hasUserAccess(user, 'cohort') ? (
                <Redirect to='/explorer' />
              ) : (
                COMMON_PAGES.unauthorized
              )}
            </RouteWrapper>
            <RouteWrapper path='/care-retention'>
              {hasUserAccess(user, 'tableau') ? (
                <ConfigurationTableau
                  environment={TABLEAU_ENVIRONMENT}
                  useAuth0={useAuth0}
                  featureFlags={featureFlags}
                >
                  <TableauRoutes />
                </ConfigurationTableau>
              ) : (
                COMMON_PAGES.unauthorized
              )}
            </RouteWrapper>
            <Route exact path='/build-a-network'>
              <RedirectWithParams to='/network-development' />
            </Route>
            <Route exact path='/risk-contract-management'>
              <RedirectWithParams
                to={
                  showNewRiskManagement
                    ? '/risk-management/your-data'
                    : '/risk-management'
                }
              />
            </Route>
            <Route exact path='/build-a-network/dashboard'>
              <RedirectWithParams to='/network-development/dashboard' />
            </Route>
            <Route exact path='/risk-contract-management/dashboard'>
              <RedirectWithParams to='/risk-management/dashboard' />
            </Route>
            <RouteWrapper path='/network-development'>
              {hasUserAccess(user, 'tableau') ? (
                <ConfigurationTableau
                  environment={TABLEAU_ENVIRONMENT}
                  useAuth0={useAuth0}
                  featureFlags={featureFlags}
                >
                  <TableauRoutes />
                </ConfigurationTableau>
              ) : (
                COMMON_PAGES.unauthorized
              )}
            </RouteWrapper>
            {showTableau && (
              <RouteWrapper exact path='/provider-performance'>
                {hasUserAccess(user, 'providerPerformance') ? (
                  <ConfigurationTableau
                    environment={TABLEAU_ENVIRONMENT}
                    useAuth0={useAuth0}
                    featureFlags={featureFlags}
                  >
                    {deprecateOtherProfilesAndProviderRoutes ? (
                      <RedirectPage />
                    ) : (
                      <TableauRoutes />
                    )}
                  </ConfigurationTableau>
                ) : (
                  COMMON_PAGES.unauthorized
                )}
              </RouteWrapper>
            )}
            {showNewProviderAdvantage && (
              <RouteWrapper path='/provider-performance-v2'>
                {hasUserAccess(user, 'providerPerformance') ? (
                  <ConfigurationProviderAdvantage
                    environment={PP_ENVIRONMENT}
                    useAuth0={useAuth0}
                    featureFlags={featureFlags}
                  >
                    <ProviderAdvantageRoutes />
                  </ConfigurationProviderAdvantage>
                ) : (
                  COMMON_PAGES.unauthorized
                )}
              </RouteWrapper>
            )}
            {showNewRiskManagement ? (
              <RouteWrapper path='/risk-management'>
                {hasUserAccess(user, 'riskManagementNational') ||
                hasUserAccess(user, 'riskContractManagementYourAco') ? (
                  <>
                    {hasUserAccess(user, 'riskContractManagementYourAco') && (
                      <ConfigurationTableau
                        environment={TABLEAU_ENVIRONMENT}
                        useAuth0={useAuth0}
                        featureFlags={featureFlags}
                      >
                        <TableauRoutes />
                      </ConfigurationTableau>
                    )}
                    {hasUserAccess(user, 'riskManagementNational') ? (
                      <RMConfiguration
                        environment={RM_ENVIRONMENT}
                        useAuth0={useAuth0}
                        featureFlags={featureFlags}
                      >
                        <RMRoutes />
                      </RMConfiguration>
                    ) : (
                      <RedirectWithParams to='/risk-management/your-data' />
                    )}
                  </>
                ) : (
                  COMMON_PAGES.unauthorized
                )}
              </RouteWrapper>
            ) : (
              <RouteWrapper path='/risk-management'>
                {hasUserAccess(user, 'tableau') ? (
                  <ConfigurationTableau
                    environment={TABLEAU_ENVIRONMENT}
                    useAuth0={useAuth0}
                    featureFlags={featureFlags}
                  >
                    <TableauRoutes />
                  </ConfigurationTableau>
                ) : (
                  COMMON_PAGES.unauthorized
                )}
              </RouteWrapper>
            )}
            {featureFlags.showNewDataverse && (
              <RouteWrapper path='/dataverse'>
                <ConfigurationDataverse
                  environment={DV_ENVIRONMENT}
                  useAuth0={useAuth0}
                  featureFlags={featureFlags}
                >
                  <DataverseRoutes />
                </ConfigurationDataverse>
              </RouteWrapper>
            )}
            {featureFlags.showCareAccelerator && (
              <RouteWrapper path='/care-accelerator'>
                {hasUserAccess(user, 'careAccelerator') ? (
                  <ConfigurationTableau
                    environment={TABLEAU_ENVIRONMENT}
                    useAuth0={useAuth0}
                    featureFlags={featureFlags}
                  >
                    <TableauRoutes />
                  </ConfigurationTableau>
                ) : (
                  COMMON_PAGES.unauthorized
                )}
              </RouteWrapper>
            )}
            <Route path='*'>
              {launchDarklyReady
                ? COMMON_PAGES.notFoundPage
                : COMMON_PAGES.loading}
            </Route>
          </Switch>
        </Layout>
      )}
    </Router>
  );
};

export default ProtectedRoutes;
