import {
  BuildANetworkIcon,
  CareRetentionIcon,
  chakraTheme,
  ClassicIcon,
  CommunityIcon,
  DataIcon,
  LaunchDarklyFeatureFlagsProps,
  MarketIntelligenceIcon,
  ProviderPerformanceIcon,
  RiskContractIcon,
  WebinarIcon,
  CareAcceleratorIcon,
  ArcadiaIcon
} from 'cj-common-react';
import mixpanel from 'mixpanel-browser';
import { HomeTileProps } from './types';
import { HOME_ENVIRONMENT } from 'config/environments/homeEnvironments';
import { communityURL, webinarsURL } from 'components/NavBar/config';
import { User } from '@auth0/auth0-react';
import { USER_PERMISSIONS_NAMESPACE_KEY } from 'config';


const PERMISSIONS = {
  marketIntelligence: [
    'read:provider_profile',
    'read:cohort_atlas',
    'read:network_advantage'
  ],
  providerPerformance: ['read:network_advantage'],
  buildANetwork: ['read:network_advantage', 'read_phi:population_insights'],
  careRetention: ['read:network_advantage', 'read_phi:population_insights'],
  riskContractManagement: [
    'read:network_advantage',
    'read_phi:population_insights'
  ],
  careAccelerator: ['read_phi:population_insights']
};

export const MARKET_INTELLIGENCE_TAGS = {
  'read:provider_profile': [
    'Provider Profile',
    'Market Profile',
    'Other profiles'
  ],
  'read:cohort_atlas': ['Cohort Profile'],
  'read:cohort_profile_user': [],
  'read:market_profile': []
};

export const WELCOME_TEXT = 'Welcome';
export const LET_US_HELP_YOU_TEXT =
  "Let us help you find what you need. We're here to assist you.";
export const CAREJOURNEY_MODULES_TEXT = 'CareJourney Modules';

export const userHasPermission = (user: User, appId: string): boolean => {
  const userPermissions = user?.[USER_PERMISSIONS_NAMESPACE_KEY] || [];
  return PERMISSIONS[appId]?.some((requiredPermission) =>
    userPermissions.includes(requiredPermission)
  );
};

export const getTileTags = (
  user: User,
  tagsByPermissions: Object
): string[] => {
  const tags: string[] = user?.[USER_PERMISSIONS_NAMESPACE_KEY].reduce(
    (acc, curr) => {
      if (tagsByPermissions[curr]) {
        return [...acc, ...tagsByPermissions[curr]];
      }
      return acc;
    },
    []
  );

  const uniqueTags = [...new Set(tags)];

  return uniqueTags;
};

export const getContentTiles = (
  featureFlags: LaunchDarklyFeatureFlagsProps,
  user: User
): HomeTileProps[] => {
  const contentTiles: HomeTileProps[] = [];

  if (
    featureFlags?.showMarketIntelligenceTile &&
    userHasPermission(user, 'marketIntelligence')
  ) {
    contentTiles.push({
      title: 'Market Intelligence',
      description:
        "Discover a market's landscape, including physicians, practice groups and facilities operating in a market and patients residing in a market.",
      tags: getTileTags(user, MARKET_INTELLIGENCE_TAGS),
      icon: (
        <MarketIntelligenceIcon
          height={30}
          width={30}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.gold}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/explorer',
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('explorer Clicked', {
          appName: 'explorer'
        });
      }
    });
  }

  if (
    featureFlags?.showProviderPerformanceTile &&
    userHasPermission(user, 'providerPerformance')
  ) {
    contentTiles.push({
      title: 'Provider Performance',
      description:
        'Understand key cost and quality indicators for providers and surface actionable opportunities for improvement.',
      tags: ['Provider Scorecard', 'Practice Group Scorecard'],
      icon: (
        <ProviderPerformanceIcon
          height={30}
          width={30}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.gold}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: featureFlags.showNewProviderAdvantage
        ? '/provider-performance-v2'
        : '/provider-performance',
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('Provider Performance Clicked', {
          appName: 'Provider Performance'
        });
      }
    });
  }

  if (
    !featureFlags?.hideContentForCjTrialMembers &&
    featureFlags?.showBuildANetworkTile &&
    userHasPermission(user, 'buildANetwork')
  ) {
    contentTiles.push({
      title: 'Network Development',
      description:
        'Craft a network of both primary care and specialists in a given market.',
      tags: ['Network Simulation Dashboard'],
      icon: (
        <BuildANetworkIcon
          height={30}
          width={30}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.gold}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/network-development',
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('Network Development Clicked', {
          appName: 'Network Development'
        });
      }
    });
  }

  if (
    !featureFlags?.hideContentForCjTrialMembers &&
    featureFlags?.showCareRetentionTile &&
    userHasPermission(user, 'careRetention')
  ) {
    contentTiles.push({
      title: 'Care Retention',
      description:
        'Understand and optimize care retention opportunities as patients flow between providers and facilities.',
      tags: [
        'Network Utilization Patterns',
        'Practice Group',
        'Facility ',
        'Referral Relationships'
      ],
      url: '/care-retention',
      icon: (
        <CareRetentionIcon
          height={30}
          width={28}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.gold}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('Care Retention Clicked', {
          appName: 'Care Retention'
        });
      }
    });
  }

  if (
    featureFlags?.showRiskContractManagementTile &&
    userHasPermission(user, 'riskContractManagement')
  ) {
    contentTiles.push({
      title: 'Risk Management',
      description:
        'Track VBO performance compared to benchmarks and uncover relevant market opportunities.',
      tags: ['VBO Comparison', 'Your VBO'],
      color: chakraTheme.colors?.blueOne,
      icon: (
        <RiskContractIcon
          height={30}
          width={30}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.blueOne}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/risk-management',
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('Risk Management Clicked', {
          appName: 'Risk Management'
        });
      }
    });
  }

  if (featureFlags?.showCarejourneyClassicTile) {
    contentTiles.push({
      title: 'CareJourney Classic',
      description:
        'Access your existing CareJourney Products here while we streamline your experience.',
      tags: ['Network Advantage', 'Population Insights', 'Cohort Atlas'],
      color: chakraTheme.colors?.blueOne,
      icon: (
        <ClassicIcon
          height={30}
          width={30}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.blueOne}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: HOME_ENVIRONMENT.PORTAL_URL,
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('Care Journey Classic App Clicked', {
          appName: 'carejouneyLegacy'
        });
      }
    });
  }

  if (
    featureFlags.showCareAccelerator &&
    userHasPermission(user, 'careAccelerator')
  ) {
    contentTiles.push({
      title: 'Patient Pulse',
      description:
        'Utilize patient-level insights to identify actionable opportunities to close care gaps and ensure patients are on the optimal care journey.',
      tags: [
        'Patient Prioritization',
        'Events',
        'Care Gaps',
        'Care Coordination'
      ],
      color: '#6386A0',
      icon: (
        <CareAcceleratorIcon
          height={30}
          width={30}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor='#6386A0'
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/care-accelerator',
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('Care Accelerator App Clicked', {
          appName: 'careAccelerator'
        });
      }
    });
  }

  return contentTiles;
};

export const getCommonTiles = (
  featureFlags: LaunchDarklyFeatureFlagsProps,
  dataverseURL: string
): HomeTileProps[] => {
  const commonTiles: HomeTileProps[] = [];

  if (featureFlags?.showDataCommonsTiles) {
    commonTiles.push({
      title: 'Webinars',
      description: 'Watch on-demand webinars with industry experts.',
      color: chakraTheme.colors?.mediumGrey,
      icon: (
        <WebinarIcon
          height={30}
          width={28}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.mediumGrey}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      ctaText: 'Explore',
      url: webinarsURL,
      handleClick: () => {
        mixpanel.track('webinars Clicked', {
          appName: 'webinars'
        });
      }
    });
  }

  if (featureFlags?.showCommunityTile) {
    commonTiles.push({
      title: 'Community',
      description:
        'Browse our library of resources for CareJourney’s products such as FAQs, user guides, and training videos.',
      color: chakraTheme.colors?.mediumGrey,
      icon: (
        <CommunityIcon
          height={30}
          width={28}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.mediumGrey}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: communityURL,
      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('community Clicked', {
          appName: 'community'
        });
      }
    });
  }
  if (featureFlags?.showDataCommonsTiles) {
    commonTiles.push({
      title: 'Dataverse',
      description: "Access CareJourney's repository of data reports.",
      color: chakraTheme.colors?.mediumGrey,
      icon: (
        <DataIcon
          height={30}
          width={28}
          color={chakraTheme.colors?.darkGrey}
          backgroundColor={chakraTheme.colors?.mediumGrey}
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      ctaText: 'Explore',
      url: dataverseURL,
      handleClick: () => {
        mixpanel.track('dataverse Clicked', {
          appName: 'dataverse'
        });
      }
    });
  }

  if (featureFlags?.showArcadiaTile) {
    commonTiles.push({
      title: 'Access Arcadia',
      description: 'Get access to Arcadia’s analytics platform.',
      color: chakraTheme.colors?.mediumGrey,
      icon: (
        <ArcadiaIcon direction='left' height={30} width={28} cursor='pointer' />
      ),
      url: 'https://arcadia.io/', // TODO: Ask product about this url

      ctaText: 'Explore',
      handleClick: () => {
        mixpanel.track('arcadia Clicked', {
          appName: 'arcadia'
        });
      }
    });
  }

  return commonTiles;
};

export const newGetContentTiles = (
  featureFlags: LaunchDarklyFeatureFlagsProps,
  user: User
): HomeTileProps[] => {
  const contentTiles: HomeTileProps[] = [];

  if (featureFlags?.showMarketIntelligenceTile) {
    contentTiles.push({
      title: 'Market Intelligence',
      description:
        "Discover a market's landscape, including physicians, practice groups and facilities operating in a market and patients residing in a market.",
      tags: getTileTags(user, MARKET_INTELLIGENCE_TAGS),
      module: 'vbc navigator',
      icon: (
        <MarketIntelligenceIcon
          height={30}
          width={30}
          color={
            userHasPermission(user, 'marketIntelligence')
              ? chakraTheme.colors?.darkGrey
              : '#747474'
          }
          backgroundColor={
            userHasPermission(user, 'marketIntelligence')
              ? chakraTheme.colors?.blueOne
              : '#747474'
          }
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/explorer',
      color: chakraTheme.colors?.blueOne,
      ctaText: 'Explore',
      isDisabled: !userHasPermission(user, 'marketIntelligence'),
      tabBackgroundColor: chakraTheme.colors?.tableHeaderBlue,
      handleClick: () => {
        mixpanel.track('explorer Clicked', {
          appName: 'explorer'
        });
      }
    });
  }
  if (featureFlags?.showRiskContractManagementTile) {
    contentTiles.push({
      title: 'Risk Management',
      description:
        'Track VBO performance compared to benchmarks and uncover relevant market opportunities.',
      tags: ['VBO Comparison', 'Your VBO'],
      color: chakraTheme.colors?.blueOne,
      module: 'vbc navigator',
      icon: (
        <RiskContractIcon
          height={30}
          width={30}
          color={
            userHasPermission(user, 'riskContractManagement')
              ? chakraTheme.colors?.darkGrey
              : '#747474'
          }
          backgroundColor={
            userHasPermission(user, 'riskContractManagement')
              ? chakraTheme.colors?.blueOne
              : '#747474'
          }
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/risk-management',
      ctaText: 'Explore',
      isDisabled: !userHasPermission(user, 'riskContractManagement'),
      tabBackgroundColor: chakraTheme.colors?.tableHeaderBlue,
      handleClick: () => {
        mixpanel.track('Risk Management Clicked', {
          appName: 'Risk Management'
        });
      }
    });
  }
  if (featureFlags?.showBuildANetworkTile) {
    contentTiles.push({
      title: 'Network Development',
      module: 'vbc navigator',
      description:
        'Craft a network of both primary care and specialists in a given market.',
      tags: ['Network Simulation Dashboard'],
      color: chakraTheme.colors?.blueOne,

      icon: (
        <BuildANetworkIcon
          height={30}
          width={30}
          color={
            userHasPermission(user, 'buildANetwork') &&
            !featureFlags?.hideContentForCjTrialMembers
              ? chakraTheme.colors?.darkGrey
              : '#747474'
          }
          backgroundColor={
            userHasPermission(user, 'buildANetwork') &&
            !featureFlags?.hideContentForCjTrialMembers
              ? chakraTheme.colors?.blueOne
              : '#747474'
          }
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/network-development',
      ctaText: 'Explore',
      isDisabled:
        !userHasPermission(user, 'buildANetwork') ||
        featureFlags?.hideContentForCjTrialMembers,
      tabBackgroundColor: chakraTheme.colors?.tableHeaderBlue,

      handleClick: () => {
        mixpanel.track('Network Development Clicked', {
          appName: 'Network Development'
        });
      }
    });
  }
  if (featureFlags?.showCareRetentionTile) {
    contentTiles.push({
      title: 'Care Retention',
      module: 'vbc navigator',
      description:
        'Understand and optimize care retention opportunities as patients flow between providers and facilities.',
      tags: [
        'Network Utilization Patterns',
        'Practice Group',
        'Facility ',
        'Referral Relationships'
      ],
      url: '/care-retention',
      color: chakraTheme.colors?.blueOne,
      tabBackgroundColor: chakraTheme.colors?.tableHeaderBlue,

      icon: (
        <CareRetentionIcon
          height={30}
          width={28}
          color={
            userHasPermission(user, 'careRetention') &&
            !featureFlags?.hideContentForCjTrialMembers
              ? chakraTheme.colors?.darkGrey
              : '#747474'
          }
          backgroundColor={
            userHasPermission(user, 'careRetention') &&
            !featureFlags?.hideContentForCjTrialMembers
              ? chakraTheme.colors?.blueOne
              : '#747474'
          }
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      ctaText: 'Explore',
      isDisabled:
        !userHasPermission(user, 'careRetention') ||
        featureFlags?.hideContentForCjTrialMembers,
      handleClick: () => {
        mixpanel.track('Care Retention Clicked', {
          appName: 'Care Retention'
        });
      }
    });
  }
  if (featureFlags?.showProviderPerformanceTile) {
    contentTiles.push({
      title: 'Provider Performance',
      description:
        'Understand key cost and quality indicators for providers and surface actionable opportunities for improvement.',
      tags: ['Provider Scorecard', 'Practice Group Scorecard'],
      module: 'provider intelligence',
      icon: (
        <ProviderPerformanceIcon
          height={30}
          width={30}
          color={
            userHasPermission(user, 'providerPerformance')
              ? chakraTheme.colors?.darkGrey
              : '#747474'
          }
          backgroundColor={
            userHasPermission(user, 'providerPerformance')
              ? chakraTheme.colors?.gold
              : '#747474'
          }
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      tabBackgroundColor: chakraTheme.colors?.lightGold,
      url: featureFlags.showNewProviderAdvantage
        ? '/provider-performance-v2'
        : '/provider-performance',
      ctaText: 'Explore',
      isDisabled: !userHasPermission(user, 'providerPerformance'),
      handleClick: () => {
        mixpanel.track('Provider Performance Clicked', {
          appName: 'Provider Performance'
        });
      }
    });
  }
  if (featureFlags?.showCareAccelerator) {
    contentTiles.push({
      title: 'Patient Pulse',
      description:
        'Utilize patient-level insights to identify actionable opportunities to close care gaps and ensure patients are on the optimal care journey.',
      tags: [
        'Patient Prioritization',
        'Events',
        'Care Gaps',
        'Care Coordination'
      ],
      module: 'care accelerator',
      color: '#6386A0',
      icon: (
        <CareAcceleratorIcon
          height={30}
          width={30}
          color={
            userHasPermission(user, 'careAccelerator') ? '#6386A0' : '#747474'
          }
          backgroundColor={
            userHasPermission(user, 'careAccelerator') ? '#6386A0' : '#747474'
          }
          backgroundOpacity='0.5'
          cursor='pointer'
        />
      ),
      url: '/care-accelerator',
      ctaText: 'Explore',
      isDisabled: !userHasPermission(user, 'careAccelerator'),
      tabBackgroundColor: chakraTheme.colors?.lighterGrey,
      handleClick: () => {
        mixpanel.track('Care accelerator Clicked', {
          appName: 'CareAccelerator'
        });
      }
    });
  }

  return contentTiles;
};
