import { HomeEnvironmentProps } from 'cj-common-react';
import { HomeEnvironmentsProps } from './types';

export const HomeEnvironments: HomeEnvironmentsProps = {
  local: {
    DOMAIN: 'dev-zto14ngg.auth0.com',
    CLIENT_ID: 'C1XHi2mpzCn8MPHZZvxAJjnkvnZzOxBG',
    AUDIENCE: 'https://api.local.carejourney.com/',
    CONNECTION_NAME: 'cj-member-users',
    BASE_APP_URL: 'http://localhost:3000',
    ENVIRONMENT: 'local',
    LAUNCH_DARKLY_CLIENT_ID: '62a74c5cd369e413a0519df9',
    PORTAL_URL: 'https://insights-dev.carejourney.com',
    CONFIG_API_URL: 'http://localhost:4006/config'
  },
  dev: {
    DOMAIN: 'auth-dev.carejourney.com',
    CLIENT_ID: 'LK7hEAqDqvNCYCU1n1Ga2pPdr0jussIm',
    AUDIENCE: 'https://api.dev.carejourney.com/',
    CONNECTION_NAME: 'cj-member-users',
    BASE_APP_URL: 'https://home-dev.carejourney.com',
    ENVIRONMENT: 'dev',
    LAUNCH_DARKLY_CLIENT_ID: '62a0e68a15f4031531a090ba',
    PORTAL_URL: 'https://insights-dev.carejourney.com',
    CONFIG_API_URL: 'https://api.dev.carejourney.com/config'
  },
  tst: {
    DOMAIN: 'auth-tst.carejourney.com',
    CLIENT_ID: 'oPsXiic1tnqrddjlQpImoQa301gG0sGR',
    AUDIENCE: 'https://api.tst.carejourney.com/',
    CONNECTION_NAME: 'cj-member-users',
    BASE_APP_URL: 'https://home-tst.carejourney.com',
    LAUNCH_DARKLY_CLIENT_ID: '62a0e69c8f0e7b14f7358502',
    ENVIRONMENT: 'tst',
    PORTAL_URL: 'https://insights-tst.carejourney.com',
    CONFIG_API_URL: 'https://api.tst.carejourney.com/config'
  },
  int: {
    DOMAIN: 'auth-int.carejourney.com',
    CLIENT_ID: 'oAYfRdVdDwqnG6LLqgw55yDA7b7zHFF0',
    AUDIENCE: 'https://api.int.carejourney.com/',
    CONNECTION_NAME: 'cj-member-users',
    BASE_APP_URL: 'https://home-int.carejourney.com',
    LAUNCH_DARKLY_CLIENT_ID: '62a0e69240d0e4154e84053d',
    ENVIRONMENT: 'int',
    PORTAL_URL: 'https://insights-int.carejourney.com',
    CONFIG_API_URL: 'https://api.int.carejourney.com/config'
  },
  prod: {
    DOMAIN: 'auth.carejourney.com',
    CLIENT_ID: 'RgZSf6tsWHi5vjs0nWVqJ5JoowrV4fYP',
    AUDIENCE: 'https://api.prod.carejourney.com/',
    CONNECTION_NAME: 'cj-member-users',
    BASE_APP_URL: 'https://home.carejourney.com',
    LAUNCH_DARKLY_CLIENT_ID: '6287e078ec20a1154b92d031',
    ENVIRONMENT: 'prod',
    PORTAL_URL: 'https://insights.carejourney.com',
    CONFIG_API_URL: 'https://api.prod.carejourney.com/config'
  }
};

export const env = process.env.REACT_APP_STAGE || 'local';
export const HOME_ENVIRONMENT: HomeEnvironmentProps = HomeEnvironments[env];
