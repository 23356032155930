import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PasswordExpiredPage } from 'cj-common-react';
import { trackPage } from 'util/trackPage';
import { CJ_PASSWORD_EXPIRED_PAGE_EVENT } from 'config';
import mixpanel from 'mixpanel-browser';
import { EXPLORER_ENVIRONMENT } from 'config/environments/explorerEnvironments';

const PasswordExpiredPageWrapper: FC = () => {
  // This wrapper page only exists to be able to have the logout method
  const { logout } = useAuth0();
  mixpanel.init(EXPLORER_ENVIRONMENT.MIXPANEL_PUBLIC_PROJECT_TOKEN, {
    api_host: EXPLORER_ENVIRONMENT.MIXPANEL_API_HOST
  });
  const handlePasswordExpiredButtonClick = (): void => {
    mixpanel.track('Sign out', {
      currentPage: window.location.pathname
    });
    window.open('https://dc.carejourney.com/_hcms/mem/logout/', '_blank');
    mixpanel.reset();
    logout({ returnTo: window.location.origin });
  };

  return (
    <PasswordExpiredPage
      handleClick={handlePasswordExpiredButtonClick}
      handleTrack={() => trackPage(CJ_PASSWORD_EXPIRED_PAGE_EVENT)}
    />
  );
};

export default PasswordExpiredPageWrapper;
