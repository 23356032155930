import { chakraTheme as theme, CJIconByArcadia } from 'cj-common-react';
import { LogoLinksProps, LogoOrLinkProps } from './types';
import CJLogo from 'components/CJLogo';
import { useHomeCtx } from 'context/HomeContext';
import { hasUserAccess } from 'util/index';
import { User } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

export const getAppLogoLinks = (
  appPage: string,
  user: User | undefined
): LogoOrLinkProps[] => {
  const { featureFlags } = useHomeCtx();

  const myReportsUrl = '/dataverse';
  const history = useHistory();

  const navBarLogo = featureFlags.showNewBrandUi ? (
    <CJIconByArcadia
      width={featureFlags.useArcadiaStyling ? 170 : 192}
      height={featureFlags.useArcadiaStyling ? 32 : 36}
      cursor='pointer'
      handleClick={() => history.push('/')}
    />
  ) : (
    <CJLogo isNavBar />
  );

  const getRiskManagementNavLinks = (
    activeTabName: string
  ): Array<LogoOrLinkProps> => {
    const riskManagementLinks: Array<LogoOrLinkProps> = [
      {
        logo: navBarLogo
      }
    ];
    if (featureFlags.showNewRiskManagement) {
      if (hasUserAccess(user, 'riskManagementNational')) {
        riskManagementLinks.push({
          label: 'National',
          url: '/risk-management',
          isActive: activeTabName === 'National' ? 'activeLinkClass' : '',
          props: {
            $isActiveTextColor: theme.colors?.blueOne
          }
        });
      }
      if (
        hasUserAccess(user, 'riskContractManagementYourAco') &&
        !featureFlags.hideContentForCjTrialMembers &&
        !featureFlags.hideYourDataTab
      ) {
        riskManagementLinks.push({
          label: 'Your Data',
          url: '/risk-management/your-data',
          isActive: activeTabName === 'Your Data' ? 'activeLinkClass' : '',
          props: {
            $isActiveTextColor: theme.colors?.blueOne
          }
        });
      }
    } else {
      riskManagementLinks.push({
        label: 'Risk Management Home',
        url: '/risk-management',
        isActive: activeTabName === 'National' ? 'activeLinkClass' : '',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      });
    }
    return riskManagementLinks;
  };

  let APP_LOGO_LINKS: LogoLinksProps = {
    explorer: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    explorerResults: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    explorerMarket: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    explorerProfiles: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    explorerCohort: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    explorerProvider: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'explorerOther-profiles': [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/explorer',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    home: [{ logo: navBarLogo }],
    'my-reports': [
      {
        logo: navBarLogo
      },
      {
        label: 'My Reports Home',
        url: myReportsUrl,
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueThree
        }
      }
    ],
    'my-reportsId': [
      {
        logo: navBarLogo
      },
      {
        label: 'My Reports Home',
        url: myReportsUrl,
        props: {
          $isActiveTextColor: theme.colors?.blueThree
        }
      }
    ],
    cohort: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/cohort',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    cohortProfile: [
      {
        logo: navBarLogo
      },
      {
        label: 'Market Intelligence Home',
        url: '/cohort',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'provider-performance': [
      {
        logo: navBarLogo
      },
      ...(featureFlags.showNewProviderAdvantage
        ? [
            {
              label: 'Provider Profiles',
              url: '/provider-performance-v2',
              props: {
                $isActiveTextColor: theme.colors?.blueOne
              }
            }
          ]
        : []),
      {
        label: featureFlags.showNewProviderAdvantage
          ? 'Dashboards'
          : 'Provider Performance Home',
        url: '/provider-performance',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'provider-performanceDashboard': [
      {
        logo: navBarLogo
      },
      {
        label: 'Provider Performance Home',
        url: '/provider-performance',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'care-retention': [
      {
        logo: navBarLogo
      },
      {
        label: 'Care Retention Home',
        url: '/care-retention',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'network-development': [
      {
        logo: navBarLogo
      },
      {
        label: 'Network Development Home',
        url: '/network-development',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'network-developmentDashboard': [
      {
        logo: navBarLogo
      },
      {
        label: 'Network Development Home',
        url: '/network-development',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    dataverse: [
      {
        logo: navBarLogo
      },
      {
        label: 'Dataverse Home',
        url: '/dataverse',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    dataverseReport: [
      {
        logo: navBarLogo
      },
      {
        label: 'Dataverse Home',
        url: '/dataverse',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ],
    'risk-management': getRiskManagementNavLinks('National'),
    'risk-managementProfiles': getRiskManagementNavLinks('National'),
    'risk-managementYour-data': getRiskManagementNavLinks('Your Data'),
    'risk-managementDashboard': getRiskManagementNavLinks('Your Data'),
    'care-accelerator': [
      {
        logo: navBarLogo
      },
      {
        label: 'Patient Pulse',
        url: '/care-accelerator',
        isActive: 'activeLinkClass',
        props: {
          $isActiveTextColor: theme.colors?.blueOne
        }
      }
    ]
  };
  if (featureFlags.showNewProviderAdvantage) {
    APP_LOGO_LINKS = {
      ...APP_LOGO_LINKS,
      'provider-performance-v2': [
        {
          logo: navBarLogo
        },
        {
          label: 'Provider Profiles',
          url: '/provider-performance-v2',
          isActive: 'activeLinkClass',
          props: {
            $isActiveTextColor: theme.colors?.blueOne
          }
        },
        ...(featureFlags.showDashboardsLink
          ? [
              {
                label: 'Dashboards',
                url: '/provider-performance',
                props: {
                  $isActiveTextColor: theme.colors?.blueOne
                }
              }
            ]
          : [])
      ],
      'provider-performance-v2Profiles': [
        {
          logo: navBarLogo
        },
        {
          label: 'Provider Profiles',
          url: '/provider-performance-v2',
          props: {
            $isActiveTextColor: theme.colors?.blueOne
          }
        },
        ...(featureFlags.showDashboardsLink
          ? [
              {
                label: 'Dashboards',
                url: '/provider-performance',
                props: {
                  $isActiveTextColor: theme.colors?.blueOne
                }
              }
            ]
          : [])
      ],
      'provider-performance-v2Results': [
        {
          logo: navBarLogo
        },
        {
          label: 'Provider Profiles',
          url: '/provider-performance-v2',
          isActive: 'activeLinkClass',
          props: {
            $isActiveTextColor: theme.colors?.blueOne
          }
        },
        ...(featureFlags.showDashboardsLink
          ? [
              {
                label: 'Dashboards',
                url: '/provider-performance',
                props: {
                  $isActiveTextColor: theme.colors?.blueOne
                }
              }
            ]
          : [])
      ],
      'provider-performance-v2Participants': [
        {
          logo: navBarLogo
        },
        {
          label: 'Provider Profiles',
          url: '/provider-performance-v2',
          isActive: 'activeLinkClass',
          props: {
            $isActiveTextColor: theme.colors?.blueOne
          }
        },
        ...(featureFlags.showDashboardsLink
          ? [
              {
                label: 'Dashboards',
                url: '/provider-performance',
                props: {
                  $isActiveTextColor: theme.colors?.blueOne
                }
              }
            ]
          : [])
      ]
    };
  }
  return APP_LOGO_LINKS[appPage] ?? [];
};
