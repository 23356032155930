import { FC } from 'react';
import NavBar from 'components/NavBar';
import { useLocation, useRouteMatch } from 'react-router';
import { Box, useTheme } from '@chakra-ui/react';
import { useHomeCtx } from 'context/HomeContext';

const Layout: FC = ({ children }) => {
  const theme = useTheme();
  const route = useRouteMatch();
  const location = useLocation();
  const {
    featureFlags: { deprecateOtherProfilesAndProviderRoutes, useArcadiaStyling }
  } = useHomeCtx();

  const shouldHideNavBar =
    (route.path === '/home' && route.isExact) ||
    (route.path === '/provider-performance' &&
      deprecateOtherProfilesAndProviderRoutes &&
      route.isExact) ||
    (location.pathname === '/explorer/other-profiles' &&
      deprecateOtherProfilesAndProviderRoutes) ||
    (location.pathname === '/explorer/provider' &&
      deprecateOtherProfilesAndProviderRoutes);

  if (shouldHideNavBar && !useArcadiaStyling) {
    return (
      <Box width='100%' backgroundColor={theme.colors?.explorerBackground}>
        {children}
      </Box>
    );
  }
  return (
    <Box marginTop={useArcadiaStyling ? '60px' : '72px'}>
      <NavBar />
      {children}
    </Box>
  );
};

export default Layout;
