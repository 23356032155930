import { EnvironmentProps } from 'cj-risk-management-ui';
import { HomeEnvironments } from './homeEnvironments';

const RM_ENVIRONMENTS = {
  local: {
    ...HomeEnvironments.local,
    PROFILE_API_URL: 'http://localhost:4005/profiles',
    PROFILE_API_ACO_ENDPOINT: '/aco',
    PROFILE_API_TIN_ENDPOINT: '/tin',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '6caa612894a18c1fa35718d19f1ed7f7',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    SEARCH_API_URL: 'http://localhost:5002/search',
    SEARCH_V2_API_URL: 'http://localhost:3003/search-v2',
    SEARCH_API_SEARCH_ENDPOINT: '/v2',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_API_LOCATIONS_ENDPOINT: '/risk-management/locations/patients',
    SEARCH_API_POPULATION_DISTRIBUTION_ENDPOINT:
      '/risk-management/population/distribution',
    CONFIG_API_RISK_MANAGEMENT_ENDPOINT: '/common/newRiskManagement',
    CONFIG_API_LEGACY_RISK_MANAGEMENT_ENDPOINT: '/common/riskManagement',
    SEARCH_API_RM_MAP_ENDPOINT: '/risk-management/map'
  },
  dev: {
    ...HomeEnvironments.dev,
    PROFILE_API_URL: 'https://api.dev.carejourney.com/profiles',
    PROFILE_API_ACO_ENDPOINT: '/aco',
    PROFILE_API_TIN_ENDPOINT: '/tin',
    SEARCH_API_URL: 'https://api.dev.carejourney.com/search',
    SEARCH_V2_API_URL: 'https://api.dev.carejourney.com/search-v2',
    SEARCH_API_SEARCH_ENDPOINT: '/v2',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_API_LOCATIONS_ENDPOINT: '/risk-management/locations/patients',
    SEARCH_API_POPULATION_DISTRIBUTION_ENDPOINT:
      '/risk-management/population/distribution',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '78ed0e94b0ff1ed4e8ef31c50567df68',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_RISK_MANAGEMENT_ENDPOINT: '/common/newRiskManagement',
    CONFIG_API_LEGACY_RISK_MANAGEMENT_ENDPOINT: '/common/riskManagement',
    SEARCH_API_RM_MAP_ENDPOINT: '/risk-management/map'
  },
  tst: {
    ...HomeEnvironments.tst,
    PROFILE_API_URL: 'https://api.tst.carejourney.com/profiles',
    PROFILE_API_ACO_ENDPOINT: '/aco',
    PROFILE_API_TIN_ENDPOINT: '/tin',
    SEARCH_API_URL: 'https://api.tst.carejourney.com/search',
    SEARCH_V2_API_URL: 'https://api.tst.carejourney.com/search-v2',
    SEARCH_API_SEARCH_ENDPOINT: '/v2',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_API_LOCATIONS_ENDPOINT: '/risk-management/locations/patients',
    SEARCH_API_POPULATION_DISTRIBUTION_ENDPOINT:
      '/risk-management/population/distribution',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: 'b552bf87940a2d93c8a3931f88f50543',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_RISK_MANAGEMENT_ENDPOINT: '/common/newRiskManagement',
    CONFIG_API_LEGACY_RISK_MANAGEMENT_ENDPOINT: '/common/riskManagement',
    SEARCH_API_RM_MAP_ENDPOINT: '/risk-management/map'
  },
  int: {
    ...HomeEnvironments.int,
    PROFILE_API_URL: 'https://api.int.carejourney.com/profiles',
    PROFILE_API_ACO_ENDPOINT: '/aco',
    PROFILE_API_TIN_ENDPOINT: '/tin',
    SEARCH_API_URL: 'https://api.int.carejourney.com/search',
    SEARCH_V2_API_URL: 'https://api.int.carejourney.com/search-v2',
    SEARCH_API_SEARCH_ENDPOINT: '/v2',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_API_LOCATIONS_ENDPOINT: '/risk-management/locations/patients',
    SEARCH_API_POPULATION_DISTRIBUTION_ENDPOINT:
      '/risk-management/population/distribution',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '3c3df4675aee7c6fd066cdda55686fec',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_RISK_MANAGEMENT_ENDPOINT: '/common/newRiskManagement',
    CONFIG_API_LEGACY_RISK_MANAGEMENT_ENDPOINT: '/common/riskManagement',
    SEARCH_API_RM_MAP_ENDPOINT: '/risk-management/map'
  },
  prod: {
    ...HomeEnvironments.prod,
    PROFILE_API_URL: 'https://api.prod.carejourney.com/profiles',
    PROFILE_API_ACO_ENDPOINT: '/aco',
    PROFILE_API_TIN_ENDPOINT: '/tin',
    SEARCH_API_URL: 'https://api.prod.carejourney.com/search',
    SEARCH_V2_API_URL: 'https://api.prod.carejourney.com/search-v2',
    SEARCH_API_SEARCH_ENDPOINT: '/v2',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_API_LOCATIONS_ENDPOINT: '/risk-management/locations/patients',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '266204ae2d7fcd3ddbf87608890ea260',
    SEARCH_API_POPULATION_DISTRIBUTION_ENDPOINT:
      '/risk-management/population/distribution',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_RISK_MANAGEMENT_ENDPOINT: '/common/newRiskManagement',
    CONFIG_API_LEGACY_RISK_MANAGEMENT_ENDPOINT: '/common/riskManagement',
    SEARCH_API_RM_MAP_ENDPOINT: '/risk-management/map'
  }
};

const env = process.env.REACT_APP_STAGE || 'local';
export const RM_ENVIRONMENT: EnvironmentProps = RM_ENVIRONMENTS[env];
