import { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Layout from 'pages/Layout';

const RouteWrapper: FC<RouteProps> = ({ children, ...args }) => (
  <Route {...args}>
    <Layout>{children}</Layout>
  </Route>
);

export default RouteWrapper;
