import { FC } from 'react';
import mixpanel from 'mixpanel-browser';
import { createBrowserHistory } from 'history';
import { useAuth0 } from '@auth0/auth0-react';
import Protected from 'components/Protected';
import { EXPLORER_ENVIRONMENT } from 'config/environments/explorerEnvironments';
import { COMMON_PAGES } from 'config';
import { Box } from '@chakra-ui/react';

export const history = createBrowserHistory();

const App: FC = () => {
  mixpanel.init(EXPLORER_ENVIRONMENT.MIXPANEL_PUBLIC_PROJECT_TOKEN, {
    api_host: EXPLORER_ENVIRONMENT.MIXPANEL_API_HOST
  });
  mixpanel.register({ domain: 'Explorer' });
  const { error } = useAuth0();
  const searchParams = new URLSearchParams(window.location.search);
  const urlError = searchParams.get('error');
  let returnComponent = (
    <Box
      background='linear-gradient(3.39deg, #F6F8FA 8.4%, rgba(255, 255, 255, 0) 46.51%)'
      minHeight='calc(100vh)'
      paddingBottom='0'
      position='relative'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Protected />
    </Box>
  );

  if (error) {
    returnComponent = COMMON_PAGES.error;
  }

  if (urlError) {
    returnComponent = COMMON_PAGES[urlError] || COMMON_PAGES.error;
  }

  return returnComponent;
};

export default App;
