import { FC } from 'react';
import { Box, Divider, Flex, Text, useTheme } from '@chakra-ui/react';
import { CJIcon, CJIconByArcadia, HomeTile } from 'cj-common-react';
import {
  getCommonTiles,
  getContentTiles,
  LET_US_HELP_YOU_TEXT,
  WELCOME_TEXT,
  CAREJOURNEY_MODULES_TEXT,
  newGetContentTiles
} from './config';
import BackgroundLogo from './BackgroundLogo/BackgroundLogo';
import { useAuth0 } from '@auth0/auth0-react';
import { HomeTileProps } from './types';
import { useHomeCtx } from 'context/HomeContext';
import SuiteDropdown from 'components/SuiteDropdown';
import { getUserMenuDropdownItems } from 'components/NavBar/config';
import { Link, useLocation } from 'react-router-dom';
import WelcomeModal from 'components/WelcomeModal';

const Home: FC = () => {
  const theme = useTheme();
  const { user, logout } = useAuth0();
  const {
    setIsChangePasswordModalOpen,
    featureFlags
  } = useHomeCtx();
  const { pathname } = useLocation();

  const contentTilesGetter = featureFlags.showTilesWithModules
    ? newGetContentTiles(featureFlags, user || {})
    : getContentTiles(featureFlags, user || {});

  const showModal =
    featureFlags.showWelcomeModalInCjhome && pathname === '/home';

  return (
    <>
      {showModal && <WelcomeModal />}
      <Flex
        position='relative'
        minH='100vh'
        maxW='100%'
        w='100vw'
        direction='column'
        backgroundColor={featureFlags.useArcadiaStyling ? theme.colors?.arcadiaBgGrey : theme.colors?.explorerBackground}
        alignItems='center'
        data-testid='new-home--container'
      >
        <BackgroundLogo />
        {!featureFlags.useArcadiaStyling && (
          <Flex
            w='100%'
            h='52px'
            marginTop='30px'
            justifyContent='space-between'
            alignItems='center'
            data-testid='new-home--header-container'
            p='0 30px'
            zIndex={1}
          >
            {featureFlags.showNewBrandUi ? (
              <CJIconByArcadia
                width={207}
                height={40}
                handleClick={() => (window.location.href = '/')}
                cursor='pointer'
              />
            ) : (
              <CJIcon
                width={213}
                height={40}
                handleClick={() => (window.location.href = '/')}
                cursor='pointer'
              />
            )}

            <SuiteDropdown
              showUser
              suiteApps={getUserMenuDropdownItems(
                user,
                logout,
                setIsChangePasswordModalOpen
              )}
            />
          </Flex>
        )}
        <Flex
          margin='0 40px'
          direction='column'
          maxW='1156px'
          data-testid='new-home--content-container'
          zIndex={1}
        >
          <Flex
            w='100%'
            h='105px'
            marginTop='50px'
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            data-testid='new-home--welcome-message--container'
          >
            <Text
              textStyle={
                featureFlags.useArcadiaStyling
                  ? 'inter-medium-16-20'
                  : 'bold-18-25'
              }
              color={theme.colors?.blueOne}
              data-testid='new-home--welcome-message--user-text'
            >
              {`${WELCOME_TEXT} ${user?.name || ''}!`}
            </Text>
            <Text
              textStyle='regular-36-40'
              fontFamily={
                featureFlags.useArcadiaStyling
                  ? 'Inter Regular'
                  : 'OpenSans Regular'
              }
              fontSize='36px'
              maxW='557px'
              color={theme.colors?.mediumGrey}
              data-testid='new-home--welcome-message--message-text'
            >
              {LET_US_HELP_YOU_TEXT}
            </Text>
          </Flex>
          <Flex
            marginTop='50px'
            direction='column'
            data-testid='new-home--what-is-your-goal--container'
          >
            {!featureFlags.showNewBrandUi && (
              <Text
                textStyle='bold-16-16'
                color={theme.colors?.mediumGrey}
                data-testid='new-home--what-is-your-goal--text'
              >
                {CAREJOURNEY_MODULES_TEXT}
              </Text>
            )}
            <Flex
              marginTop='20px'
              flexWrap='wrap'
              gap='30px'
              data-testid='new-home--what-is-your-goal--tiles-container'
            >
              {contentTilesGetter.map(
                ({
                  title,
                  description,
                  ctaText,
                  color,
                  icon,
                  tags,
                  handleClick,
                  url,
                  isDisabled,
                  module,
                  tabBackgroundColor
                }: HomeTileProps) => (
                  <Box
                    key={title}
                    as={
                      isDisabled ? 'div' : url?.includes('https') ? 'a' : Link
                    }
                    {...(url?.includes('https')
                      ? { href: url }
                      : { to: url as string })}
                  >
                    <HomeTile
                      useArcadiaStyling={featureFlags.useArcadiaStyling}
                      key={title}
                      isDisabled={isDisabled}
                      module={module}
                      isNewDesign={featureFlags.showTilesWithModules}
                      title={title}
                      description={description}
                      ctaText={ctaText}
                      color={color || theme.colors?.gold}
                      icon={icon}
                      tags={tags}
                      handleClick={handleClick}
                      height='185px'
                      width='364px'
                      tagBackgroundColor={
                        featureFlags.useArcadiaStyling
                          ? tabBackgroundColor
                          : undefined
                      }
                    />
                  </Box>
                )
              )}
            </Flex>
            <Divider
              margin='30px 0'
              w='100%'
              borderTop={`1px solid ${theme.colors?.lightGrey}`}
            />
            <Flex
              flexWrap='wrap'
              gap='30px'
              marginBottom='26px'
              data-testid='new-home--what-is-your-goal--common-tiles-container'
            >
              {getCommonTiles(
                featureFlags,
                '/dataverse'
              ).map(
                ({
                  title,
                  description,
                  ctaText,
                  icon,
                  handleClick,
                  url
                }: HomeTileProps) => (
                  <a key={title} href={url}>
                    <HomeTile
                      useArcadiaStyling={featureFlags.useArcadiaStyling}
                      title={title}
                      description={description}
                      ctaText={ctaText}
                      icon={icon}
                      handleClick={handleClick}
                      height='123px'
                      width='364px'
                      color={theme.colors?.mediumGrey}
                    />
                  </a>
                )
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Home;
