import { ExplorerEnvironmentProps } from 'cj-explorer-ui';
import { HomeEnvironments } from './homeEnvironments';

const EXPLORER_ENVIRONMENTS = {
  local: {
    ...HomeEnvironments.local,
    SEARCH_API_URL: 'http://localhost:5002/search',
    SEARCH_API_SEARCH_ENDPOINT: '/search',
    SEARCH_API_MARKET_SEARCH_ENDPOINT: '/markets',
    SEARCH_API_COUNTIES_ENDPOINT: '/counties',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/suggestions',
    PROFILE_API_URL: 'http://localhost:4005/profiles',
    PROFILE_API_REFERRALS_PROVIDER_ENDPOINT: '/providers/referrals',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer',
    PROFILE_API_EXPLORER_MARKET_ENDPOINT: '/market/explorer',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '6caa612894a18c1fa35718d19f1ed7f7',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  dev: {
    ...HomeEnvironments.dev,
    SEARCH_API_URL: 'https://api.dev.carejourney.com/search',
    SEARCH_API_SEARCH_ENDPOINT: '/search',
    SEARCH_API_MARKET_SEARCH_ENDPOINT: '/markets',
    SEARCH_API_COUNTIES_ENDPOINT: '/counties',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/suggestions',
    PROFILE_API_URL: 'https://api.dev.carejourney.com/profiles',
    PROFILE_API_REFERRALS_PROVIDER_ENDPOINT: '/providers/referrals',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer',
    PROFILE_API_EXPLORER_MARKET_ENDPOINT: '/market/explorer',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '78ed0e94b0ff1ed4e8ef31c50567df68',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  tst: {
    ...HomeEnvironments.tst,
    SEARCH_API_URL: 'https://api.tst.carejourney.com/search',
    SEARCH_API_SEARCH_ENDPOINT: '/search',
    SEARCH_API_MARKET_SEARCH_ENDPOINT: '/markets',
    SEARCH_API_COUNTIES_ENDPOINT: '/counties',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/suggestions',
    PROFILE_API_URL: 'https://api.tst.carejourney.com/profiles',
    PROFILE_API_REFERRALS_PROVIDER_ENDPOINT: '/providers/referrals',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer',
    PROFILE_API_EXPLORER_MARKET_ENDPOINT: '/market/explorer',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: 'b552bf87940a2d93c8a3931f88f50543',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  int: {
    ...HomeEnvironments.int,
    SEARCH_API_URL: 'https://api.int.carejourney.com/search',
    SEARCH_API_SEARCH_ENDPOINT: '/search',
    SEARCH_API_MARKET_SEARCH_ENDPOINT: '/markets',
    SEARCH_API_COUNTIES_ENDPOINT: '/counties',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/suggestions',
    PROFILE_API_URL: 'https://api.int.carejourney.com/profiles',
    PROFILE_API_REFERRALS_PROVIDER_ENDPOINT: '/providers/referrals',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer',
    PROFILE_API_EXPLORER_MARKET_ENDPOINT: '/market/explorer',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '3c3df4675aee7c6fd066cdda55686fec',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  prod: {
    ...HomeEnvironments.prod,
    SEARCH_API_URL: 'https://api.prod.carejourney.com/search',
    SEARCH_API_SEARCH_ENDPOINT: '/search',
    SEARCH_API_MARKET_SEARCH_ENDPOINT: '/markets',
    SEARCH_API_COUNTIES_ENDPOINT: '/counties',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/suggestions',
    PROFILE_API_URL: 'https://api.prod.carejourney.com/profiles',
    PROFILE_API_REFERRALS_PROVIDER_ENDPOINT: '/providers/referrals',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer',
    PROFILE_API_EXPLORER_MARKET_ENDPOINT: '/market/explorer',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '266204ae2d7fcd3ddbf87608890ea260',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  }
};

const env = process.env.REACT_APP_STAGE || 'local';
EXPLORER_ENVIRONMENTS[env].SEARCH_API_MARKET_MAP_SEARCH_ENDPOINT =
  '/markets/map';
export const EXPLORER_ENVIRONMENT: ExplorerEnvironmentProps =
  EXPLORER_ENVIRONMENTS[env];
