import axios from 'axios';
import {
  HomeEnvironmentProps,
  LaunchDarklyFeatureFlagsProps
} from 'cj-common-react';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { HomeContextProps } from './types';
import mixpanel from 'mixpanel-browser';
import { EXPLORER_ENVIRONMENT } from 'config/environments/explorerEnvironments';
import { featureFlagsFallback } from './utils';

export const HomeContext = createContext<HomeContextProps | null>(null);

export interface HomeProviderProps {
  children: ReactNode;
  useAuth: Function;
  environment: HomeEnvironmentProps;
  featureFlags: LaunchDarklyFeatureFlagsProps;
}

export const HomeProvider: ({
  children,
  useAuth,
  environment,
  featureFlags
}: HomeProviderProps) => JSX.Element = ({
  children,
  useAuth,
  featureFlags
}: HomeProviderProps) => {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState<boolean>(false);
  const [userMember, setUserMember] = useState<string>('');
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);

  const [token, setToken] = useState<string>('');
  const { getAccessTokenSilently, user } = useAuth();

  useEffect(() => {
    const storedDate = localStorage.getItem('lastVisitDate');
    const currentDate = new Date().toISOString().split('T')[0];

    if (!storedDate || storedDate !== currentDate) {
      localStorage.setItem('lastVisitDate', currentDate);
      setShowWelcomeModal(true);
    }
  });

  const USER_AUTHORIZATION_NAMESPACE_KEY =
    'https://carejourney.com/user_authorization';

  mixpanel.init(EXPLORER_ENVIRONMENT.MIXPANEL_PUBLIC_PROJECT_TOKEN, {
    api_host: EXPLORER_ENVIRONMENT.MIXPANEL_API_HOST
  });
  mixpanel.register({ domain: 'Home' });

  useEffect(() => {
    if (user) {
      const setUpUser = async (): Promise<void> => {
        try {
          const userAuth = user[USER_AUTHORIZATION_NAMESPACE_KEY];
          const organization =
            userAuth?.member_name?.toUpperCase() || 'No organization';

          setUserMember(userAuth?.member_name?.toUpperCase());
          mixpanel.people.set({
            Organization: organization,
            $name: user?.name || 'None',
            $email: user?.email || 'None'
          });
          mixpanel.identify(user?.sub || 'None');
        } catch (e) {
          console.error('Error setting up user');
          console.error(e);
        }
      };
      setUpUser();
    }
  }, [user]);

  const configureApi: () => Promise<void> = async () => {
    try {
      const token = await getAccessTokenSilently(); // TODO: why does moving this cause re-render loop
      axios.interceptors.request.use(async (req) => {
        req.headers.Authorization = 'Bearer ' + token;
        return req;
      });
      setToken(token);
    } catch (error) {
      console.error('Error trying to get token: ', error);
    }
  };
  if (useAuth && token === '') {
    configureApi();
  }

  const context = {
    isChangePasswordModalOpen,
    setIsChangePasswordModalOpen,
    userMember,
    setUserMember,
    featureFlags: featureFlagsFallback(featureFlags),
    showWelcomeModal,
    setShowWelcomeModal,
    // Mixpanel
    mixpanel
  };
  return (
    <HomeContext.Provider value={context}>{children}</HomeContext.Provider>
  );
};

export function useHomeCtx(): HomeContextProps {
  const context = HomeContext;
  if (!context) {
    console.error('There is an error with my reports context');
  }
  return useContext(context) as HomeContextProps;
}
