import {
  ErrorPage,
  LoadingPage,
  NoAccessPage,
  NotFoundPage
} from 'cj-common-react';
import PasswordExpiredPageWrapper from 'components/PasswordExpiredPageWrapper/PasswordExpiredPageWrapper';
import { goToHome } from 'util/goToHome';
import { trackPage } from 'util/trackPage';
import { CommonPagesProps } from './types';

export const MIX_PANEL_LABELS = {
  FULL_NAME: 'Provider Name',
  SPECIALTY: 'Specialty',
  CJ_SPECIALTY: 'Subspecialty',
  PRACTICE_GROUP: 'Practice Group',
  MANUAL_SEARCH: 'Manual Search',
  EPISODES: 'Episodes',
  DEFAULT_ORGANIZATION: 'No organization',
  LOCATION: 'Location'
};

export const REQUIRED_COHORT_PERMISSION_RESOURCE = 'cohort_atlas';
export const REQUIRED_COHORT_PROFILE_ROLE = 'cohort_profile_user';

export const REQUIRED_EXPLORER_PERMISSION_RESOURCE = 'provider_profile';

export const NEW_CJHOME_REQUIRED_EXPLORER_PERMISSION_RESOURCE = [
  'provider_profile',
  'cohort_atlas'
];
export const REQUIRED_EXPLORER_ROLE = 'explorer_user';

export const REQUIRED_MY_REPORTS_PERMISSION_RESOURCE = [
  'network_advantage',
  'cohort_atlas',
  'population_insights',
  'common_reports'
];
export const REQUIRED_TABLEAU_PERMISSION_RESOURCE = [
  'network_advantage',
  'population_insights'
];
export const REQUIRED_RISK_MANAGEMENT_PERMISSION_RESOURCE = [
  'network_advantage'
];
export const REQUIRED_PROVIDER_PERFOMANCE_PERMISSION_RESOURCE = 'network_advantage';

export type AccessListType = {
  [name: string]: {
    permission: string | string[];
    role?: string;
  };
};

export const REQUIRED_RISK_CONTRACT_MANAGEMENT_YOUR_ACO_PERMISSION_RESOURCE =
  'population_insights';

export const REQUIRED_CARE_ACCELERATOR_PERMISSION_RESOURCE =
  'population_insights';

export const ACCESSES_LIST: AccessListType = {
  explorer: {
    permission: REQUIRED_EXPLORER_PERMISSION_RESOURCE,
    role: REQUIRED_EXPLORER_ROLE
  },
  cohort: {
    permission: REQUIRED_COHORT_PERMISSION_RESOURCE,
    role: REQUIRED_COHORT_PROFILE_ROLE
  },
  myReports: {
    permission: REQUIRED_MY_REPORTS_PERMISSION_RESOURCE
  },
  tableau: {
    permission: REQUIRED_TABLEAU_PERMISSION_RESOURCE
  },
  riskManagementNational: {
    permission: REQUIRED_RISK_MANAGEMENT_PERMISSION_RESOURCE
  }
};

export const NEW_CJHOME_ACCESSES_LIST: AccessListType = {
  explorer: {
    permission: NEW_CJHOME_REQUIRED_EXPLORER_PERMISSION_RESOURCE,
    role: REQUIRED_EXPLORER_ROLE
  },
  cohort: {
    permission: REQUIRED_COHORT_PERMISSION_RESOURCE,
    role: REQUIRED_COHORT_PROFILE_ROLE
  },
  myReports: {
    permission: REQUIRED_MY_REPORTS_PERMISSION_RESOURCE
  },
  tableau: {
    permission: REQUIRED_TABLEAU_PERMISSION_RESOURCE
  },
  riskManagementNational: {
    permission: REQUIRED_RISK_MANAGEMENT_PERMISSION_RESOURCE
  },
  riskContractManagementYourAco: {
    permission: [REQUIRED_RISK_CONTRACT_MANAGEMENT_YOUR_ACO_PERMISSION_RESOURCE]
  },
  careAccelerator: {
    permission: REQUIRED_CARE_ACCELERATOR_PERMISSION_RESOURCE
  },
  providerPerformance: {
    permission: [REQUIRED_PROVIDER_PERFOMANCE_PERMISSION_RESOURCE]
  }
};

export const USER_AUTHORIZATION_NAMESPACE_KEY =
  'https://carejourney.com/user_authorization';
export const USER_PERMISSIONS_NAMESPACE_KEY =
  'https://carejourney.com/permissions';

export const CJ_ERROR_PAGE_EVENT = 'CareJourney Home Error Page';
export const CJ_PASSWORD_EXPIRED_PAGE_EVENT =
  'CareJourney Home Password Expired Page';
export const CJ_UNAUTHORIZED_PAGE_EVENT = 'CareJourney Home Unauthorized Page';
export const CJ_NOT_FOUND_PAGE_EVENT = 'CareJourney Home Not Found Page';

export const COMMON_PAGES: CommonPagesProps = {
  unauthorized: (
    <NoAccessPage
      handleClick={goToHome}
      handleTrack={() => trackPage(CJ_UNAUTHORIZED_PAGE_EVENT)}
    />
  ),
  password_expired: <PasswordExpiredPageWrapper />,
  error: (
    <ErrorPage
      handleClick={goToHome}
      handleTrack={() => trackPage(CJ_ERROR_PAGE_EVENT)}
    />
  ),
  notFoundPage: (
    <NotFoundPage
      handleClick={goToHome}
      handleTrack={() => trackPage(CJ_NOT_FOUND_PAGE_EVENT)}
    />
  ),
  loading: <LoadingPage />
};

// This needs to be fixed to have a hardcoded hash calculated for this key
// In the local auth0 token, so we can run securemode
export const LD_LOCAL_USER_KEY = 'LDLocalUserKey';
