import { LaunchDarklyFeatureFlagsProps } from 'cj-common-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HomeProvider } from '../../context/HomeContext';
import { ConfigurationHomeProps } from './types';

export default function ConfigurationHome({
  useAuth0,
  children,
  environment
}: ConfigurationHomeProps): JSX.Element {
  const launchDarklyFeatureFlags: LaunchDarklyFeatureFlagsProps = useFlags();
  return (
    <HomeProvider
      useAuth={useAuth0}
      environment={environment}
      featureFlags={launchDarklyFeatureFlags}
    >
      {children}
    </HomeProvider>
  );
}
