import { GlossaryEntriesProps } from 'cj-common-react/dist/types/components/Glossary/GlossaryProps';

export const metricData = {
  'Advanced Payment Model (APM)': {
    definition:
      'Provider participation status in CMS advanced payment models (APMs).',
    source: 'Medicare FFS Claims Data.'
  },
  Address: {
    definition:
      "All the known addresses that the provider practices from. This is not a direct one-to-one match with the provider's practice groups",
    source: '1. Physician Compare. <br/> 2. NPPES'
  },
  'APM Type': {
    definition: 'Name of the APM the provider is participating in.',
    source: 'Medicare FFS Claims Data.'
  },
  'Area Deprivation Index': {
    definition:
      'ADI is an index that measures area SDOH (including population education attainment, income/employment stats, and housing and transportation situation) using census data.',
    source: 'Open Data, 2015 - 2019 5-year estimates of American Community.'
  },
  'Average HCC Score': {
    definition:
      'Average HCC risk score of the patients that the provider has rendered service to.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'AWV Compliance (Provider Profile)': {
    definition:
      'Percent of attributed patients eligible for annual wellness visit (AWV) who were compliant.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.',
    methodology:
      "1. Medicare FFS: MSSP attribution. <br/> 2. Commercial: CareJourney's Commercial attribution."
  },
  'AWV Eligibility (Provider Profile)': {
    definition:
      'Percent of attributed patients eligible for an annual wellness visit (AWV).',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.',
    methodology:
      "1. Medicare FFS: MSSP attribution. <br/> 2. Commercial: CareJourney's Commercial attribution."
  },
  CBSA: {
    definition:
      'Provider core-based statistical area (CBSA). A CBSA is a group of zip codes around an urban center.',
    source: 'Medicare FFS Claims Data.'
  },
  'Commercial Lives (Market Profile)': {
    definition:
      "Reflects county-level commercial lives in CareJourney's source data, based on patient address. May not be reflective of all commercial lives within a given county.",
    source: 'Commercial Claims Data.'
  },
  'Commercial Lives (Provider Profile)': {
    definition:
      "Reflects provider-level attributed commercial lives from CareJourney's source data. May not be reflective of all commercial lives that the provider serves.",
    methodology:
      'We are using CLM data. CLM comes from the physician source (while PCC is from the payer and is a subset of CLM). <br/><br/>Commercial patients are attributed to PCP based on primary care services - most often billed as annual wellness visits (AWVs) or evaluation and management (E/M) services - that are furnished by primary care providers (PCPs) and other applicable specialty care physicians in a given calendar year. <br/><br/>A single primary care service rendered by a physician would trigger assignment. If there are multiple services rendered by multiple providers, assignment is based on the plurality of care, followed by the most recent date of service and the total dollars paid for those services.',
    source: 'Commercial Claims Data.'
  },

  'Commercial PMPY': {
    definition: 'Cost per member per year for attributed commercial patients. ',
    source: 'Commercial Claims Data.'
  },
  'Comparison to Peers': {
    definition:
      "The percentile of the provider's performance measure compared against all other providers with the same measure available in the same core-based statistical area (CBSA).",
    source: 'CareJourney.'
  },
  'Cost Score': {
    definition:
      'CareJourney Provider Performance Index (PPI) Cost Score. The cost performance score of the provider. Higher = More cost effective. ',
    methodology:
      'CareJourney Provider Performance Index (PPI) cost scores rank providers and practice groups using episode cost metrics. PPI cost score is created by combining episodic costs into an overall cost score for each provider or practice group. The score is then compared against peer groups within specialty, subspecialty, and geography (defined by core-based statistical area, or CBSA) to place providers and practice groups into quintiles. These quintiles correspond to index scores of 1 (lowest quintile) - 5 (highest quintile). For detailed methodology, please reach out to your Member Services Advisor. ',
    source: 'Medicare FFS Claims Data.'
  },
  'County CBSA': {
    definition:
      'The core-based-statistical area (CBSA) the county belongs to. A CBSA is a group of zip codes around an urban center.',
    source: 'Medicare FFS Claims Data.'
  },
  'County ID': {
    definition:
      'FIPS County Code. The Federal Information Processing Standard Publication 6-4 (FIPS 6-4) is a five-digit Federal Information Processing Standards code which uniquely identified counties and county equivalents in the United States, certain U.S. possessions, and certain freely associated states.',
    source: 'Open Data, 2015 - 2019 5-year estimates of American Community.'
  },
  'County State': {
    definition: 'State of the county.',
    source: 'Medicare FFS Claims Data.'
  },
  'Covid Vaccine Compliance': {
    definition:
      'Percent of attributed patients who received their full Covid vaccination dosages.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  Denominator: {
    definition:
      'The denominator of the measure, e.g., for the measure Percent Low Back Pain Compliance, the denominator is the number of patients who had eligible low back pain primary diagnosis claims.',
    source: 'Medicare FFS Claims Data.'
  },
  'Direct Contracting % Strongly Aligned': {
    definition:
      'Percent of attributed Direct Contracting patients that are strongly aligned to the provider. For direct contracting, if a patient has more than 50% of their Primary Care Qualified Evaluation & Management (PQEM) claims billed by a provider, they are considered Strongly Aligned.',
    source: 'Medicare FFS Claims Data.',
    methodology:
      'Click <a href="https://innovation.cms.gov/files/x/dc-faqs.pdf#page=23" target="_blank">here</a> (Appendix B) to see the full list of PQEM.'
  },
  'Direct Contracting Lives': {
    definition:
      'The number of Direct Contracting Entity (DCE) patients that are attributed to this provider (For Performance Year 2021 only). ',
    methodology:
      'Medicare FFS beneficiaries are attributed to a provider under DCE if they have their plurality of PQEM claims billed by the provider in a given year. Click <a href="https://innovation.cms.gov/files/x/dc-faqs.pdf#page=23" target="_blank">here</a> (Appendix B) to see the full list of PQEM.',
    source: 'Medicare FFS Claims Data.'
  },
  'Distressed Community Index': {
    definition:
      'The Distressed Communities Index (DCI) examines economic well-being at the zip code level in order to provide a detailed view of the divided landscape of American prosperity.',
    source:
      'Open Data, 2014 - 2018 5-year estimates of American Community Survey conducted by Census Bureau.'
  },
  'Episode - Average Comparison Cost': {
    definition:
      'The average cost of the episode in the comparison CBSA, based on performance by the top comparable providers.',
    source: 'Medicare FFS Claims Data'
  },
  'Episode - Benchmark ': {
    definition:
      'Average cost of the episode in the selected CBSA. Benchmark is not risk adjusted. ',
    source: 'Medicare FFS Claims Data.'
  },
  'Episode - Cost ': {
    definition: 'Total cost associated with the episode ',
    source: 'Medicare FFS Claims Data',
    methodology:
      "Episode cost is calculated using EGM's episoding rules. EGM identifies all relevant diagnosis and procedure codes, as well as the length of an episode. The total cost of the episode takes into account of costs of all relevant services happened within the duration of the episode."
  },
  'Episode - Type': {
    definition:
      'Name of the episode. An individual disease event which is part of a progressing condition. CareJourney Explorer uses Episode Grouper for Medicare (EGM) to define episodes.',
    source: 'CareJourney.'
  },
  'Episode - Volume ': {
    definition:
      'The total number of episodes attributed to the provider in a given time period.  ',
    methodology:
      'Episodes are attributed to provider based on episode type. </br></br>  <b>For condition episodes</b>, episodes are attributed to provider who has the plurality of encounters during the duration of the episode. Chronic episode has a three-year lookback period and is closed when patient leaves Medicare program or deceases. </br></br>  For example, for year 2020, 66 osteoarthritis episodes are attributed to Dr. John Doe, and it is the number of osteoarthritis episodes that he has the plurality of encounters from 2018 to 2020. </br></br> <b>For treatment episodes</b>, episodes are are attributed to provider based on who billed the EGM triggering code. A treatment episode remains open until certain number of days (depending on the specific episodes) after the procedure passed remained no activity.</br></br> For more detailed methodology on episodes, please contact your Member Services Advisor.',
    source: 'Medicare FFS Claims Data.'
  },
  'Flu Compliance (Provider Profile)': {
    definition:
      'Percent of eligible attributed patients who received their flu shot.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.',
    methodology:
      "1. Medicare FFS: MSSP attribution. <br/> 2. Commercial: CareJourney's Commercial attribution."
  },
  'Flu Eligibility (Provider Profile)': {
    definition: 'Percent of attributed patients eligible to get a flu shot.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.',
    methodology:
      "1. Medicare FFS: MSSP attribution. <br/> 2. Commercial: CareJourney's Commercial attribution."
  },
  'Hospice PMPY': {
    definition:
      'Hospice per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Hospital Name': {
    definition: 'Hospital the provider is affiliated with.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Home Health PMPY': {
    definition:
      'Home health agency per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Home Health Visits Per 1K': {
    definition: 'Home health agency (HHA) visits per 1,000 member-years.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Inpatient PMPY': {
    definition:
      'Inpatient per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'IP Admits Per 1K': {
    definition: 'Inpatient admissions per 1000 member-years.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'MA Lives (Market Profile)': {
    definition:
      'The number of Medicare Advantage (MA) beneficiaries in a given county.',
    source: 'Medicare FFS Claims Data'
  },
  'MSSP % Strongly Aligned': {
    definition:
      'Percent of attributed MSSP patients that are strongly aligned to the provider.  For MSSP,  if a provider rendered within $150 of the total spend for a patient, they are considered Strongly Aligned. ',
    source: 'Medicare FFS Claims Data.'
  },
  'MSSP Lives': {
    definition:
      'The number of Medicare Shared Savings Program patients that are attributed to this provider.',
    methodology:
      'Medicare FFS beneficiaries are attributed to a provider under MSSP if they have their plurality of primary care services (measured by allowed charged) billed by a provider in a given year. We are using MSSP version 7 assignment methodology. Click <a href="https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/sharedsavingsprogram/Downloads/Shared-Savings-Losses-Assignment-Spec-V7.pdf#page=12" target="_blank">here</a> to see the detailed methodology. ',
    source: 'Medicare FFS Claims Data.'
  },
  'MSSP PMPY': {
    definition: 'Cost per member per year for attributed MSSP patients. ',
    source: 'Medicare FFS Claims Data.'
  },
  'Measure Description': {
    definition: 'Name of the quality measure.',
    source: 'CareJourney.'
  },
  'Medicare FFS Lives (Market Profile)': {
    definition: 'The number of Medicare FFS beneficiaries in a given county. ',
    source: 'Medicare FFS Claims Data'
  },
  'Medicare FFS Lives (Provider Profile)': {
    definition:
      'The distinct total number of attributed Medicare FFS beneficiaries in Direct Contracting and MSSP.',
    source: 'Medicare FFS Claims Data'
  },
  'Medicare Lives': {
    definition: 'The number of attributed Medicare FFS patients.',
    methodology:
      'This is calculated as MSSP Lives Only + DCE Lives Only - Shared Lives.',
    source: 'Medicare FFS Claims Data.'
  },
  NPI: {
    definition: 'National Physician Identifier (NPI) of the provider.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  Name: {
    definition: 'Provider Name.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Network Status': {
    definition:
      'The network status indicator of the provider based on network structure file provided by user.',
    source: 'Network Structure File (provided by member).'
  },
  Numerator: {
    definition:
      'The numerator of the measure, e.g., for the measure Percent Low Back Pain Compliance, the numerator of the measure is the number of patients who did not have an imaging study within 28 days of the diagnosis of low back pain.',
    source: 'Medicare FFS Claims Data.'
  },
  'Outpatient PMPY': {
    definition:
      'Outpatient per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Panel Size': {
    definition:
      'The total number of patients that the provider has seen in a given year. This includes all the patients that are attributed to them as well as those the provider has rendered service to.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Part B DME PMPY': {
    definition:
      'Part B durable medical equipment (DME) per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Patient Risk Score, HCC': {
    definition:
      'HCC scores, or patient risk scores offer insights on the costs and risks of the patient panel. Hierarchical condition category (HCC) coding is a risk-adjustment model originally designed to estimate future health care costs for patients. The CMS-HCC risk score for a beneficiary is the sum of the score or weight attributed to each of the demographic factors and HCCs within the model.  Lower risk scores suggest relatively better health and lower expected cost.',
    methodology:
      'CareJourney uses the raw HCC risk scores that the CMS-HCC model algorithm produces, rather than normalizing the scores.</br></br><b>HCC Score = Demographic Variable Risk Factors + Disease Hierarchical Condition Category Risk Factors + Disease/Disabled Interaction Risk Factors</b></br></br>The risk factors are defined by CMS-HCC model. We are currently offering HCC scores based on 2017 CMS-HCC algorithm (version 22).',
    source: 'Medicare FFS Claims Data.'
  },
  'PCP Visits Per 1K': {
    definition: 'PCP encounters per 1,000 member-years.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Practice Group Affiliation': {
    definition:
      'The name of the practice group that the provider is affiliated with. This can range from one to many.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.',
    methodology:
      'Practice groups are associated with PECOS PAC identification numbers. We get the NPI to PAC ID mapping from open data sources including the Base Provider Enrollment File, Physician Compare, and NPPES.</br> The level of aggregation among practice groups is variable - in some cases, practice groups are set up very similarly to TINs, where one PAC ID covers the entirety of a health system network. In other cases, PAC IDs could be for groups within a TIN, such as an individual office location.'
  },
  'Professional PMPY': {
    definition:
      'Part B per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Provider Name': {
    definition: 'Provider name.',
    source: 'Medicare FFS Claims Data.'
  },
  'Quality Measure - Description': {
    definition: 'Name of the quality measure.',
    source: 'CareJourney.'
  },
  'Quality Measure - Performance': {
    definition:
      'The probability of the provider doing better or worse than the actual results that we are showing for them for a specific quality measure.',
    source: 'CareJourney.',
    methodology:
      'For providers in Orthopedic Surgeries, Oncology/Hematology and Cardiology specialties (i.e. specialties with Enhanced PPI available), a 85% would mean that we expect this provider to do better 85% of the time. Higher = Better </br> </br> For providers in the rest of the specialties, a 85% would mean that we expect the provider to do better 15% (1 - 85%) of the times. Lower = Better'
  },
  'Quality Measure - Type': {
    definition:
      'Type of quality measure. CareJourney offers two kinds of quality measures, general measures, which are sourced from Choosing Wisely, AHRQ, MIPS, and other industry known standards, and appropriateness, which are sourced from Global Appropriateness Measures.',
    source: 'CareJourney.'
  },
  'Quality Score': {
    definition:
      'CareJourney Provider Performance Index (PPI) Quality Score. The quality performance score of the provider. Higher = Better quality performance.',
    methodology:
      'CareJourney Provider Performance Index (PPI) quality scores rank providers based on quality metrics from MIPS, Choosing Wisely, AHRQ, and GAM measures. The quality score is created by combining applicable quality measures of the specific provider into an overall quality score for each provider or practice group. The score is then compared against peer groups within specialty, subspecialty, and geography (defined by core-based statistical area, or CBSA) to place providers and practice groups into quintiles. These quintiles correspond to scores of 1 (lowest quintile) - 5 (highest quintile). For detailed methodology, please reach out to your Member Services Advisor.',
    source: 'Medicare FFS Claims Data.'
  },
  'Referral Percentage': {
    definition:
      'Percentage of total allowed amount occurred by referring to the downstream providers in a given year.',
    source: 'Medicare FFS.'
  },
  'Risk Adjusted PMPY': {
    definition:
      'Per member per year (PMPY) risk adjusted medical spending for the attributed population; adjusted by average HCC score.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Rural/Urbanicity (RUCA)': {
    definition:
      'The rural-urban commuting area (RUCA) codes classify U.S. census tracts using measures of population density, urbanization, and daily commuting.',
    source:
      'Open Data. The most recent RUCA codes are based on data from the 2010 decennial census and the 2006-2010 American Community Survey.'
  },
  'Shared Lives': {
    definition:
      'The number of DC and MSSP patients in common that are attributed to this provider in a given year.',
    source: 'Medicare FFS Claims Data.'
  },
  'Shared Percentage': {
    definition: 'Percentage of total allowed amount of the shared patients.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'SNF Admits Per 1K': {
    definition:
      'Skilled nursing facility (SNF) admissions per 1000 member-years.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'SNF PMPY': {
    definition:
      'Skilled nursing facility per member per year (PMPY) medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Social Vulnerability Index': {
    definition:
      'SVI is developed by the CDC to help emergency response planners and public health officials identify and map communities that will most likely need support before, during, and after a hazardous event. SVI is measured taking several factors into accounts, such as poverty, transportation, housing, and education attainment.',
    source:
      'Open Data, 2014 - 2018 5-year estimates of American Community Survey conducted by Census Bureau.'
  },
  'Social Vulnerability Index - Socioeconomic': {
    definition:
      'Socioeconomic status variables include: below poverty, unemployed, income, no high school diploma.',
    source:
      'Open Data, 2014 - 2018 5-year estimates of American Community Survey conducted by Census Bureau'
  },
  'Social Vulnerability Index - Household Composition & Disability': {
    definition:
      'Household composition & disability variables include: aged 65 or older, aged 17 or younger, older than age 5 with a disability, single-parent households.',
    source:
      'Open Data, 2014 - 2018 5-year estimates of American Community Survey conducted by Census Bureau'
  },
  'Social Vulnerability Index - Minority Status & Language': {
    definition:
      'Minority status & language variables include: minority, speak English “less than well”.',
    source:
      'Open Data, 2014 - 2018 5-year estimates of American Community Survey conducted by Census Bureau'
  },
  'Social Vulnerability Index - Housing Type & Transportation': {
    definition:
      'Housing type & transportation variables include: multi-unit structures, mobile homes, crowding, no vehicle, group quarters.',
    source:
      'Open Data, 2014 - 2018 5-year estimates of American Community Survey conducted by Census Bureau'
  },
  Specialty: {
    definition: 'Provider specialty.',
    source: 'Medicare FFS Claims Data.'
  },
  State: {
    definition: 'Provider state.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  Subspecialty: {
    definition:
      "Provider sub-specialty, derived from CareJourney Taxonomy using based on the provider's claims data.",
    source: 'CareJourney.'
  },
  'TCM Compliance': {
    definition:
      'Percent of attributed patients eligible for transitional care management (TCM) who were compliant.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  'Top providers with shared patients': {
    definition:
      'Name of top provider with shared patients based on allowed amount.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.',
    methodology:
      '<b>For PCPs</b>, shared patients relationships are established with any provider at any time throughout the calendar year. <br/> <br/>For instance, for family medicine doctor Dr. John Doe, the top 10 providers are based on patients that are attributed to him that went to during the calendar year, ranked by total allowed amount, regardless if it was before or after their visit to Dr. John Doe.<br/> <br/> <b>For Specialists</b>, shared patients relationships are established with any provider that the patient sees within the next 30 days of seeing the provider.<br/> <br/> For instance, for cardiologist Dr. Jane Doe, the top 10 providers are based on patients they rendered care to that went to see other specialists within the next 30 days of their visit to Dr. Jane Doe, ranked by total allowed amount.'
  },
  'Total Lives': {
    definition:
      'The total number of beneficiaries that reside in a given county.',
    source:
      'Both FFS and Commercial are using open data from the US Census Bureau.',
    methodology:
      'The Census produces the estimates using a “top-down” approach. Given that it is generally more reliable to estimate the change of a larger population, the Census begins by estimating the monthly population at the national level by age, sex, race, and Hispanic origin. It then produces estimates of the total annual populations of counties, which then gets summed to the state level. With the national characteristics, state total, and county total estimates created, the Census produces estimates of states and counties by age, sex, race, and Hispanic origin.'
  },
  'Total Patients Seen': {
    definition:
      'The number of total patients the provider has seen in a given year.',
    source: 'Medicare FFS Claims Data'
  },
  'Total PMPY': {
    definition:
      'Per member per year (PMPY) total medical spending for the attributed population.',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  }
};

export const newGlossaryItems = {
  'Adjusted O/E Ratio': {
    definition:
      'The Observed to Expected (O/E) Ratio compares a provider’s actual performance to expected for a given episode. A provider’s expected performance reflects the risk profile of their patient population and local market utilization patterns for that given episode. For providers with attributed volume fewer than 100 episodes, an adjustment is applied to their O/E Ratio to increase the weight of the local market experience and offset volatility in the provider’s observed performance',
    source: 'Medicare FFS Claims Data.'
  },
  'APM Affiliations': {
    definition:
      'The name of the CMS Advanced Payment Model (APM) that the provider is affiliated with. This can range from one to many. ',
    source: 'Medicare FFS Claims Data.'
  },
  'Appropriateness Measures': {
    definition:
      'Appropriateness of care measures attributed to a provider at the population level. These measures are generally sourced from Global Appropriateness Measures (GAM).  Please note that this is an additional module and permissions may vary.  ',
    source: 'Medicare FFS Claims Data.'
  },
  'Average HCC Score': {
    definition:
      'Average HCC risk score of the patients that the provider has rendered service to. CareJourney leverages the CMS-HCC model for the Medicare patient panel. ',
    source: '1. Medicare FFS Claims Data. <br/> 2. Commercial Claims Data.'
  },
  Conditions: {
    definition: `A way of grouping episodes of care. Condition episodes include the following episode types:
- Inpatient Medical
- Outpatient Acute Medical
- Chronic Medical
- Medical Oncology
- Radiation Oncology
- Total Cost of Care`,
    source: 'Medicare FFS Claims Data.'
  },
  'Cost Score': {
    definition:
      'CareJourney Provider Performance Index (PPI) Cost Score. The cost performance score of the provider. Higher = More cost effective. ',
    methodology:
      "The CareJourney Provider Performance Index (PPI) Cost Score is the the cost performance score of the provider. This score is constructed by benchmarking, combining, and risk-adjusting episode costs for each provider.  The score is available on multiple levels (i.e., specialty, episode, provider), is calculated on a 1-5 scale, with 5 signifiying the most favorable performance in a region.  The score leverages CareJourney's Episodes for Physician Performance, which leverages a 'best of' approach using the most highly adopted open episode groupers on the market. For more details on the methodology behind the score, please reach out to your Member Services Advisor.",
    source: 'Medicare FFS Claims Data.'
  },
  'Episode Based Measures': {
    definition: `A set of quality measures that are calculated at the episode-level in order to provide insights into performance at the episode level.  Measures include:
- Mortality Rate
- ED per 1K
- Unplanned Readmissions per 1K
- Institutional Long Term Length of Stay`,
    source: 'Medicare FFS Claims Data.'
  },
  Opportunity: {
    definition: `The cost savings opportunity if a provider could improve their performance aligned to the top performing peers in their market.
Opportunity = (Average Observed Cost) * (Number of Episodes) * (Adjusted O/E Ratio - Comparison O/E Ratio)
Comparison O/E Ratio = SUM(Weighted Observed Ratio) / SUM(Number of Episodes) within a given year, CBSA, specialty and episode`,
    source: 'Medicare FFS Claims Data.'
  },
  'Patient Segmentation': {
    definition:
      'The segmentation of the patient population attributed to the provider for Total Cost of Care analyses.  The segmentation approach is leveraging the High Needs High Cost frailty segmentation for Medicare. ',
    source: 'Medicare FFS Claims Data.'
  },
  'Primary Place of Service': {
    definition:
      'The primary place of service is the place of service the provider bills the majority of claims in. CareJourney derives this by taking the place of service on the claims and analyizing the spend and claims billed at each place of service. ',
    source: 'Medicare FFS Claims Data.'
  },
  'Procedures & Diagnoses': {
    definition:
      'The list surfaces the top procedures and diagnoses billed by the provider during the measurement period for a more granular view of their billing patterns.',
    source: 'Medicare FFS Claims Data.'
  },
  'Process Quality Measures': {
    definition:
      'A set of process quality measures attributed to a provider at the population level. These measures are generally sourced from the most commonly known measure stewards. ',
    source: 'Medicare FFS Claims Data.'
  },
  'Place of Service': {
    definition:
      'The associated places of service that the provider bills at (i.e., Office, Hospital Inpatient, Skilled Nursing Facility, etc.) derived via claims activity. ',
    source: 'Medicare FFS Claims Data.'
  },
  'PPI Quality (Episode Complications)': {
    definition:
      "The overall quality score for the provider leveraging CareJourney(')s approach to quality (potentially avoidable complications). ",
    source: 'Medicare FFS Claims Data.'
  },
  'PPI Quality (TCC Complications)': {
    definition:
      "The quality score for the provider leveraging CareJourney(')s approach to quality (potentially avoidable complications) from a Total Cost of Care perspective leveraging an ACO REACH-like methodology. ",
    source: 'Medicare FFS Claims Data.'
  },
  'Practice Group Affiliations': {
    definition:
      "The name of the practice group that the provider is affiliated with. This can range from one to many and is derived via claims activity. Please note this practice group definition leverages the Organization(')s Billing NPI. ",
    source: 'Medicare FFS Claims Data.'
  },
  'Quality Score': {
    definition:
      'CareJourney Provider Performance Index (PPI) Quality Score. The quality performance score of the provider. Higher = Better quality performance.',
    methodology:
      'The CareJourney Provider Performance Index (PPI) Quality Score is the the quality performance score of the provider. This score is constructed by benchmarking, combining, and risk-adjusting episode outcomes for each provider.  The score is available on multiple levels (i.e., specialty, episode, provider), is calculated on a 1-5 scale, with 5 signifiying the most favorable performance in a region.  The score leverages CareJourney(\')s Episodes for Physician Performance, which leverages a "best of" approach using the most highly adopted open episode groupers on the market. For more details on the methodology behind the score, please reach out to your Member Services Advisor.',
    source: 'Medicare FFS Claims Data.'
  },
  'Referrals to Imaging and Test': {
    definition:
      "Referrals to Imaging and Test infers relationships between multiple providers by using CareJourney(')s inferred referral methodology between PCPs to specialists and facilities, sourced by claims activity. ",
    source: 'Medicare FFS Claims Data.'
  },
  'Share of Total Episodes': {
    definition: `The number of episodes divided by the total number of episodes across treatments and conditions for the provider (e.g. 20 Hip Replacements / 200 Total Episodes).`,
    source: 'Medicare FFS Claims Data.'
  },
  'Share of Total Patients': {
    definition:
      'The number of patients per segment divided by the total number of patients across all segments for the provider.',
    source: 'Medicare FFS Claims Data.'
  },
  Treatments: {
    definition: `A way of grouping episodes of care. Treatment episodes include the following episode types:
- Inpatient Procedure
- Inpatient or Outpatient/Ambulatory Surgery Center Procedure
- Outpatient/Ambulatory Surgery Center or Office Procedure
- Outpatient/Ambulatory Surgery Center Procedure
- Perinatal`,
    source: 'Medicare FFS Claims Data.'
  }
};

export const mergeObjects = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): GlossaryEntriesProps => {
  const merged = {};
  for (const key in obj1) {
    merged[key] = obj1[key];
  }
  for (const key in obj2) {
    merged[key] = obj2[key];
  }

  const sortedKeys = Object.keys(merged).sort();
  const sortedObject = {};
  for (const key of sortedKeys) {
    sortedObject[key] = merged[key];
  }
  return sortedObject;
};
