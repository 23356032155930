import { CJIcon, RedirectIcon, Button } from 'cj-common-react';
import { Text, Flex, useTheme } from '@chakra-ui/react';
import { FC, useEffect } from 'react';

export const RedirectPage: FC = () => {
  const chakraTheme = useTheme();

  useEffect(() => {
    const timer = setTimeout(
      () => window.location.replace('https://home.carejourney.com'),
      10000
    );
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Flex
        alignItems='center'
        direction='column'
        minHeight='calc(100vh - 74px)'
      >
        <Text
          mt='50px'
          textStyle='regular-18-25'
          fontSize='180px'
          lineHeight='245px'
          color='#EAEBEC'
          whiteSpace='nowrap'
        >
          we’ve moved!
        </Text>
        <CJIcon
          dataTestId='cj-icon'
          direction='up'
          height={43}
          width={227}
          margin='-67px 0 45px 0'
        />
        <Text
          mb='45px'
          textStyle='semiBold-30-36'
          color={chakraTheme.colors.darkBlue}
          data-testId='redirect-message-id'
        >
          This profile has a new home. Enjoy the improved experience!
        </Text>
        <Text
          mb='45px'
          textStyle='regular-18-25'
          color={chakraTheme.colors.mediumGrey}
          textAlign='center'
        >
          The datasets and dashboards that you love and use have been moved to a
          new <br /> module inside CareJourney Home.
        </Text>
        <RedirectIcon margin='0 0 45px 0' />
        <Text textStyle='regular-16-16' color={chakraTheme.colors.mediumGrey}>
          If you are not redirected to the new page automatically, please
        </Text>
        <Button
          textStyle='semiBold-14-16'
          dataTestId='redirect-button-id'
          color={chakraTheme.colors.white}
          backgroundColor={chakraTheme.colors.blueTwo}
          mt='10px'
          mb='45px'
          p='7px 12px'
          borderRadius='3px'
          width='192px'
          _hover={{
            cursos: 'pointer'
          }}
          onClick={() =>
            window.location.replace('https://home.carejourney.com')
          }
        >
          Go to CareJourney Home
        </Button>
      </Flex>
    </>
  );
};
