import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationStyleProps } from './types';
import SuiteDropdown from 'components/SuiteDropdown';
import NavLinks from './NavLinks';
import { capitalizeFirstLetter } from 'util/index';
import GlossaryModal from 'components/GlossaryModal';
import {
  metricData,
  newGlossaryItems,
  mergeObjects
} from 'components/GlossaryModal/config';
import mixpanel from 'mixpanel-browser';
import { Box, Divider, Flex, useTheme } from '@chakra-ui/react';
import { getHomeApps, getUserMenuDropdownItems } from './config';
import { useAuth0 } from '@auth0/auth0-react';
import { useHomeCtx } from 'context/HomeContext';

const NavBarWrapper: FC = () => {
  const { user, logout } = useAuth0();
  const {
    setIsChangePasswordModalOpen,
    featureFlags
  } = useHomeCtx();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();
  const [isGlossaryModalOpen, setIsGlossaryModalOpen] =
    useState<boolean>(false);

  const [, appName, subPath = ''] = pathname.split('/');
  const appPage = appName + capitalizeFirstLetter(subPath);

  const appPagesStyles: LocationStyleProps = {
    home: {
      background: theme.colors?.white
      //  textColor: theme.colors?.white
    }, // TODO: Home does not have a search bar
    explorer: {
      background: theme.colors?.white
    }, // All bars will be white, remove this after deployment
    explorerResults: {
      background: theme.colors?.white
    },
    explorerMarket: {
      background: theme.colors?.white
    },
    cohort: {
      background: theme.colors?.white
    },
    cohortProfile: {
      background: theme.colors?.white
    },
    tableau: {
      background: theme.colors.white
    },
    tableauReport: {
      background: theme.colors.white
    },
    default: {
      background: theme.colors?.white
    }
  };

  const styles = appPagesStyles[appPage] || appPagesStyles.default;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const toggleModal = () => {
    setIsGlossaryModalOpen(!isGlossaryModalOpen);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onGlossaryModalClose = () => {
    setIsGlossaryModalOpen(false);
  };

  return (
    <>
      <GlossaryModal
        title={
          featureFlags.showNewBrandUi ? 'Glossary' : 'CareJourney Glossary'
        }
        isOpen={isGlossaryModalOpen}
        onClose={onGlossaryModalClose}
        glossaryEntries={
          featureFlags.useNewProviderProfileGlossary
            ? mergeObjects(metricData, newGlossaryItems)
            : metricData
        }
        mixpanel={mixpanel}
        data-testid='glossary-modal--container'
      />
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        height={featureFlags.useArcadiaStyling ? '60px' : '72px'}
        left='0px'
        top='0px'
        borderWidth={featureFlags.useArcadiaStyling ? '0 0 1px 0' : '2px 2px'}
        position='fixed'
        zIndex='10'
        background={styles.background || theme.colors?.white}
        data-testid='navbar-cnt-id'
      >
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          gap='45px'
          width='100%'
          marginLeft='30px'
        >
          <NavLinks toggleModal={toggleModal} appPage={appPage} />
        </Box>
        <Flex
          height='100%'
          justifyContent='flex-end'
          alignItems='center'
          marginRight='25px'
        >
          <SuiteDropdown
            showUser
            suiteApps={getUserMenuDropdownItems(
              user,
              logout,
              setIsChangePasswordModalOpen
            )}
          />
          <Divider
            margin='0 5px 0 7px'
            borderColor={theme.colors?.lightGrey}
            height='25px'
            w='1px'
            orientation='vertical'
          />
          <SuiteDropdown
            suiteApps={getHomeApps(
              user,
              history,
              featureFlags
            )}
          />
        </Flex>
      </Box>
    </>
  );
};

export default NavBarWrapper;
