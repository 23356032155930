import {
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import { Button } from 'cj-common-react';
import { useHomeCtx } from 'context/HomeContext';
import { FC, useState } from 'react';

const WelcomeModal: FC = () => {
  const currentModalVersion = '2'; // Increment this version when the modal content changes
  const { showWelcomeModal, setShowWelcomeModal, featureFlags } = useHomeCtx();

  const shouldModalOpen = (): boolean => {
    const storedVersion = localStorage.getItem('welcomeModalVersion');
    return (
      storedVersion !== currentModalVersion ||
      (featureFlags.showNewWelcomeModalDesign && showWelcomeModal)
    );
  };

  const [isOpen, setIsOpen] = useState(shouldModalOpen());

  const closeModal = (): void => {
    setShowWelcomeModal(false);
    localStorage.setItem('welcomeModalVersion', currentModalVersion);
    setIsOpen(false);
  };

  const title = featureFlags.showNewWelcomeModalDesign
    ? 'Big News! Medicare Advantage (MA) Data is Here!'
    : 'We have news!';

  const name = featureFlags.showNewBrandUi ? 'our homepage' : 'CJ Home';

  const description = featureFlags.showNewWelcomeModalDesign
    ? 'We’ve expanded Risk Management to include Medicare Advantage (MA) profiles, adding to our Medicare FFS dataset for a more comprehensive view of the market.'
    : `We’ve updated ${name} organizing modules to better serve you with our latest product packages.`;

  const bulletSectionIntro = featureFlags.showNewWelcomeModalDesign
    ? 'What this means for you:'
    : 'Explore the new layout and discover:';

  const listItems = !featureFlags.showNewWelcomeModalDesign
    ? [
        {
          title: 'VBC Navigator:',
          description:
            'Offers insights into value-based care, aiding in strategic contracting and network optimization through a focus on key success drivers. Modules: Risk Management, Care Retention'
        },
        {
          title: 'Provider Intelligence:',
          description:
            'Provides detailed provider profiles and referral networks, supporting contracting and improvement efforts with comprehensive data analysis. Module: Provider Performance'
        },
        {
          title: 'Care Accelerator:',
          description:
            'Delivers real-time panel information for improved financial performance and care coordination, integrating seamlessly into existing workflows. Module: Patient Pulse'
        }
      ]
    : [
        {
          title: 'Deeper Market Insights',
          description:
            'Understand MA enrollment trends by geography, identify underserved areas, and compare MA and Traditional Medicare populations to assess market dynamics.',
          separator: ' - '
        },
        {
          title: 'Better Contract Strategy',
          description:
            'Pinpoint high-potential provider groups, assess patient volume, and refine network expansion strategies.',
          separator: ' - '
        },
        {
          title: 'Stronger Performance Assessment',
          description:
            'Compare key metrics like HCC scores, quality, and utilization across payers and plan types to uncover improvement opportunities.',
          separator: ' -  '
        }
      ];

  return (
    <Modal isCentered size='3xl' isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay>
        <ModalContent padding='30px' width='80%'>
          <Text
            fontFamily={
              featureFlags.showNewWelcomeModalDesign
                ? 'Inter Bold'
                : 'OpenSans Semi Bold'
            }
            fontSize='20px'
            lineHeight={
              featureFlags.showNewWelcomeModalDesign ? '27px' : '24px'
            }
            marginBottom='30px'
          >
            {title}
          </Text>
          <Text
            fontFamily={
              featureFlags.showNewWelcomeModalDesign
                ? 'Inter Semi Bold'
                : 'OpenSans Semi Bold'
            }
            fontSize='14px'
            lineHeight={
              featureFlags.showNewWelcomeModalDesign ? '17px' : '19px'
            }
            marginBottom='20px'
          >
            {description}
          </Text>
          <Text
            fontFamily={
              featureFlags.showNewWelcomeModalDesign
                ? 'Inter bold'
                : 'OpenSans Regular'
            }
            fontSize='14px'
            lineHeight={
              featureFlags.showNewWelcomeModalDesign ? '17px' : '14px'
            }
            marginBottom={
              featureFlags.showNewWelcomeModalDesign ? '10px' : '30px'
            }
          >
            {bulletSectionIntro}
          </Text>
          <UnorderedList marginBottom='20px'>
            {listItems.map((item) => (
              <ListItem
                marginBottom={
                  featureFlags.showNewWelcomeModalDesign ? '10px' : '20px'
                }
                data-testid={`welcome-modal-list-item-${item.title}`}
                lineHeight={
                  featureFlags.showNewWelcomeModalDesign ? '17px' : '19px'
                }
                key={item.title}
              >
                <Text
                  fontFamily={
                    featureFlags.showNewWelcomeModalDesign
                      ? 'Inter Semi Bold'
                      : 'OpenSans Semi Bold'
                  }
                  fontSize='14px'
                  lineHeight={
                    featureFlags.showNewWelcomeModalDesign ? '17px' : '19px'
                  }
                  display='inline'
                  marginRight={
                    featureFlags.showNewWelcomeModalDesign ? '0px' : '6px'
                  }
                  textDecoration={
                    featureFlags.showNewWelcomeModalDesign
                      ? 'underline'
                      : 'none'
                  }
                >
                  {item.title}
                </Text>
                {item.separator && (
                  <Text
                    lineHeight='17px'
                    display='inline'
                    marginRight='3px'
                    marginLeft='3px'
                  >
                    {item.separator}
                  </Text>
                )}
                <Text
                  display='inline'
                  fontFamily={
                    featureFlags.showNewWelcomeModalDesign
                      ? 'Inter Regular'
                      : 'OpenSans Regular'
                  }
                  fontSize='14px'
                  lineHeight={
                    featureFlags.showNewWelcomeModalDesign ? '17px' : '19px'
                  }
                >
                  {item.description}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
          {featureFlags.showNewWelcomeModalDesign ? (
            <>
              <Text
                display='inline'
                fontFamily='Inter Semi Bold'
                fontSize='14px'
                lineHeight='17px'
                marginBottom='20px'
              >
                Limited-Time Access:{' '}
                <Text
                  display='inline'
                  fontFamily='Inter Regular'
                  fontSize='14px'
                  lineHeight='17px'
                >
                  All members can explore this new dataset through{' '}
                  <Text as='span' textDecoration='underline' display='inline'>
                    March 31
                  </Text>
                  . After that,{' '}
                  <Text as='span' fontFamily='Inter Semi Bold' display='inline'>
                    members who have not added the MA population will need to
                    reach out
                  </Text>{' '}
                  to learn how to continue access. If you already have the{' '}
                  <Text as='span' fontFamily='Inter Semi Bold' display='inline'>
                    MA package
                  </Text>
                  , this data will remain available to you.
                </Text>
              </Text>
              <Text
                display='inline'
                fontFamily='Inter Semi Bold'
                fontSize='14px'
                lineHeight='17px'
                marginBottom='30px'
              >
                Ready to explore?{' '}
                <Text
                  display='inline'
                  fontFamily='Inter Regular'
                  fontSize='14px'
                  lineHeight='17px'
                >
                  Head over to the Risk Management module and start your search.
                  To learn more, reach out to{' '}
                  <Text
                    as='a'
                    href='mailto:support@arcadiasolutions.com'
                    color='blue.600'
                    textDecoration='underline'
                    display='inline'
                  >
                    support@arcadiasolutions.com
                  </Text>{' '}
                  or{' '}
                  <Text
                    as='a'
                    href='https://dataverse.carejourney.com/community-office-hours'
                    color='blue.600'
                    textDecoration='underline'
                    display='inline'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {' '}
                    book time with our team here
                  </Text>
                  .
                </Text>
              </Text>
            </>
          ) : (
            <Text textStyle='regular-14-19' marginBottom='30px'>
              For questions or inquiries about accessing additional modules,
              please contact support@carejourney.com We’re ready to assist!
            </Text>
          )}
          <Button marginLeft='auto' variant='solid' onClick={closeModal}>
            Got it!
          </Button>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default WelcomeModal;
