import { useState, useEffect, FC } from 'react';
import {
  Button,
  chakraTheme,
  CheckIcon,
  CJLoadingIcon,
  CloseIcon,
  WarningIcon
} from 'cj-common-react';
import { ChangePasswordModalProps } from './types';
import {
  CHANGE_PASSWORD_ERROR_SUBTITLE_TEXT,
  CHANGE_PASSWORD_ERROR_TITLE_TEXT,
  CONFIRM_CHANGE_PASSWORD_TEXT,
  MAIL_SENT_TEXT
} from './config';
import {
  Box,
  Modal,
  useTheme,
  ModalOverlay,
  ModalContent,
  Flex,
  Text
} from '@chakra-ui/react';
import usePasswordChange from 'hooks/usePasswordChange';
import { useAuth0 } from '@auth0/auth0-react';
import { useHomeCtx } from 'context/HomeContext';

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ isOpen }) => {
  const theme = useTheme();
  const { user } = useAuth0();
  const { setIsChangePasswordModalOpen } = useHomeCtx();

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const { error, loading, changePassword } = usePasswordChange(user?.email);

  useEffect(() => {
    if (isOpen) {
      setIsEmailSent(false);
    }
  }, [isOpen]);

  const handleClickYes = (): void => {
    setIsEmailSent(true);
    changePassword();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => setIsChangePasswordModalOpen(false)}
    >
      <ModalOverlay />
      <ModalContent
        width='500px'
        maxHeight='189px'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='flex-start'
        backgroundColor='white'
        transition='all 0.3s ease-in-out'
        padding='30px'
        boxShadow={theme.shadow.bigShadow}
        borderRadius='6px'
        maxWidth='-webkit-fill-available'
        data-testid='change-password-modal--container'
      >
        <Flex
          display='flex'
          width='100%'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text textStyle='semiBold-20-27' color={theme.colors?.mediumGrey}>
            Change Password
          </Text>
          <CloseIcon
            width={22.5}
            height={22.5}
            cursor='pointer'
            handleClick={() => setIsChangePasswordModalOpen(false)}
          />
        </Flex>
        <Box marginTop='30px' width='100%'>
          {loading ? (
            <Flex
              width='100%'
              justifyContent='center'
              alignItems='center'
              data-testid='change-password-modal--loading-container'
            >
              <CJLoadingIcon />
            </Flex>
          ) : (
            <Flex>
              {isEmailSent ? (
                error ? (
                  <Flex
                    width='100%'
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='center'
                    data-testid='change-password-error--container'
                  >
                    <Flex>
                      <Flex
                        w='16px'
                        h='16px'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <WarningIcon
                          variant='outlined'
                          width={15}
                          height={15}
                          color={theme.colors?.red}
                        />
                      </Flex>
                      <Text
                        marginLeft='5px'
                        color={theme.colors?.mediumGrey}
                        textStyle='semiBold-14-16'
                      >
                        {CHANGE_PASSWORD_ERROR_TITLE_TEXT}
                      </Text>
                    </Flex>
                    <Text
                      marginTop='15px'
                      color={theme.colors?.mediumGrey}
                      textStyle='regular-12-16'
                    >
                      {CHANGE_PASSWORD_ERROR_SUBTITLE_TEXT}
                    </Text>
                  </Flex>
                ) : (
                  <>
                    <CheckIcon
                      width={16}
                      height={12}
                      color={theme.colors?.green}
                    />
                    <Text
                      textStyle='semiBold-14-16'
                      color={theme.colors?.mediumGrey}
                      marginLeft='5px'
                    >
                      {MAIL_SENT_TEXT}
                    </Text>
                  </>
                )
              ) : (
                <Text
                  textStyle='regular-14-16'
                  color={theme.colors?.mediumGrey}
                >
                  {CONFIRM_CHANGE_PASSWORD_TEXT}
                </Text>
              )}
            </Flex>
          )}
        </Box>

        {!isEmailSent && (
          <Box
            display='flex'
            width='100%'
            marginTop='35px'
            justifyContent='flex-end'
            alignItems='center'
            gap='10px'
            data-testid='change-password-modal-btns-container'
          >
            <Button
              width='80px'
              height='29px'
              margin={0}
              padding={0}
              backgroundColor={chakraTheme.colors?.red}
              onClick={() => setIsChangePasswordModalOpen(false)}
              dataTestId='change-password-modal-btn-cancel'
            >
              No
            </Button>
            <Button
              width='84px'
              height='29px'
              margin={0}
              padding={0}
              onClick={handleClickYes}
              dataTestId='change-password-modal-btn-apply'
            >
              Yes
            </Button>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
