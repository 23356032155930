import { TableauEnvironmentProps } from 'cj-tableau-ui';
import { HomeEnvironments } from './homeEnvironments';
import { TableauEnvironmentsProps } from './types';

const TABLEAU_ENVIRONMENTS: TableauEnvironmentsProps = {
  local: {
    ...HomeEnvironments.local,
    TABLEAU_IFRAME_DOMAIN: 'classic-int'
  },
  dev: {
    ...HomeEnvironments.dev,
    TABLEAU_IFRAME_DOMAIN: 'classic-dev'
  },
  tst: {
    ...HomeEnvironments.tst,
    TABLEAU_IFRAME_DOMAIN: 'classic-tst'
  },
  int: {
    ...HomeEnvironments.int,
    TABLEAU_IFRAME_DOMAIN: 'classic-int'
  },
  prod: {
    ...HomeEnvironments.prod,
    TABLEAU_IFRAME_DOMAIN: 'classic'
  }
};

const env = process.env.REACT_APP_STAGE || 'local';
export const TABLEAU_ENVIRONMENT: TableauEnvironmentProps =
  TABLEAU_ENVIRONMENTS[env];
