import { User } from '@auth0/auth0-react';
import {
  USER_PERMISSIONS_NAMESPACE_KEY,
  NEW_CJHOME_ACCESSES_LIST
} from 'config';

export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export type AccessType =
  | 'explorer'
  | 'cohort'
  | 'myReports'
  | 'tableau'
  | 'riskManagementNational'
  | 'riskContractManagementYourAco'
  | 'careAccelerator'
  | 'providerPerformance';

export const userHasAtLeast1ARequiredPermission = (
  userPermissions: string[],
  requiredPermissions: string[] | string
): boolean => {
  const count = (permissions): {} =>
    permissions.reduce(
      (amounts, permissionName) => ({
        ...amounts,
        [permissionName]: (amounts[permissionName] || 0) + 1
      }),
      {}
    );

  const duplicates = (permissions): string[] =>
    Object.keys(permissions).filter(
      (repeatTimes) => permissions[repeatTimes] > 1
    );

  const uniqueUserPermissions = [...new Set(userPermissions)];

  const hasPermission = Boolean(
    duplicates(count(uniqueUserPermissions.concat(requiredPermissions))).length
  );
  return hasPermission;
};

export const hasUserAccess = (
  user: User | undefined,
  access: AccessType
): boolean =>
  userHasAtLeast1ARequiredPermission(
    user?.[USER_PERMISSIONS_NAMESPACE_KEY]?.map(
      (permission) => permission.split(':')[1]
    ) || [],
    NEW_CJHOME_ACCESSES_LIST[access].permission
  );
