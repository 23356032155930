import { MyReportsEnvironmentProps } from 'cj-my-reports-ui';
import { HomeEnvironments } from './homeEnvironments';
import { MyReportsEnvironmenstProps } from './types';

const MY_REPORTS_ENVIRONMENTS: MyReportsEnvironmenstProps = {
  local: {
    ...HomeEnvironments.local,
    REPORTS_API_URL: 'http://localhost:4005/local',
    REPORTS_API_LIST_BY_ORG_URL: '/products',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '6caa612894a18c1fa35718d19f1ed7f7',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  dev: {
    ...HomeEnvironments.dev,
    REPORTS_API_URL: 'https://api.dev.carejourney.com/reports',
    REPORTS_API_LIST_BY_ORG_URL: '/products',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '78ed0e94b0ff1ed4e8ef31c50567df68',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  tst: {
    ...HomeEnvironments.tst,
    REPORTS_API_URL: 'https://api.tst.carejourney.com/reports',
    REPORTS_API_LIST_BY_ORG_URL: '/products',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: 'b552bf87940a2d93c8a3931f88f50543',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  int: {
    ...HomeEnvironments.int,
    REPORTS_API_URL: 'https://api.int.carejourney.com/reports',
    REPORTS_API_LIST_BY_ORG_URL: '/products',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '3c3df4675aee7c6fd066cdda55686fec',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  },
  prod: {
    ...HomeEnvironments.prod,
    REPORTS_API_URL: 'https://api.prod.carejourney.com/reports',
    REPORTS_API_LIST_BY_ORG_URL: '/products',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '266204ae2d7fcd3ddbf87608890ea260',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com'
  }
};

const env = process.env.REACT_APP_STAGE || 'local';
export const MY_REPORTS_ENVIRONMENT: MyReportsEnvironmentProps =
  MY_REPORTS_ENVIRONMENTS[env];
