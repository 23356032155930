import { Component, ErrorInfo, ReactNode } from 'react';
import { Props, State } from './types';

class ErrorBoundary extends Component<Props, State> {
  public state = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    const { ErrorComponent } = this.props;

    // if has error return the error component
    if (hasError) {
      return ErrorComponent;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
