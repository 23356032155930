import { EnvironmentProps } from 'cj-provider-advantage-ui';
import { HomeEnvironments } from './homeEnvironments';

const PROVIDER_PERFORMANCE_ENVIRONMENT = {
  local: {
    ...HomeEnvironments.local,
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '6caa612894a18c1fa35718d19f1ed7f7',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_URL: 'http://localhost:4006/config',
    SEARCH_API_URL: 'http://localhost:5002/search',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_V2_API_URL: 'http://localhost:3003/search-v2',
    PROFILE_API_URL: 'http://localhost:4005/profiles'
  },
  dev: {
    ...HomeEnvironments.dev,
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '78ed0e94b0ff1ed4e8ef31c50567df68',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_URL: 'https://api.dev.carejourney.com/config',
    SEARCH_API_URL: 'https://api.dev.carejourney.com/search',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_V2_API_URL: 'https://api.dev.carejourney.com/search-v2',
    PROFILE_API_URL: 'https://api.dev.carejourney.com/profiles',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer'
  },
  tst: {
    ...HomeEnvironments.tst,
    DOMAIN: 'auth-tst.carejourney.com',
    CLIENT_ID: 'Z7gQeFheavA4DGfzAqJ7zvv5GTQ317fb',
    AUDIENCE: 'https://api.int.carejourney.com/',
    BASE_APP_URL: '',
    ENVIRONMENT: 'tst',
    LAUNCH_DARKLY_CLIENT_ID: '',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_URL: 'https://api.tst.carejourney.com/config',
    SEARCH_API_URL: 'https://api.tst.carejourney.com/search',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_V2_API_URL: 'https://api.tst.carejourney.com/search-v2',
    PROFILE_API_URL: 'https://api.tst.carejourney.com/profiles',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer'
  },
  int: {
    ...HomeEnvironments.int,
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '3c3df4675aee7c6fd066cdda55686fec',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_URL: 'https://api.int.carejourney.com/config',
    SEARCH_API_URL: 'https://api.int.carejourney.com/search',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_V2_API_URL: 'https://api.int.carejourney.com/search-v2',
    PROFILE_API_URL: 'https://api.int.carejourney.com/profiles',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer'
  },
  prod: {
    ...HomeEnvironments.prod,
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '266204ae2d7fcd3ddbf87608890ea260',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    CONFIG_API_URL: 'https://api.prod.carejourney.com/config',
    SEARCH_API_URL: 'https://api.prod.carejourney.com/search',
    SEARCH_API_SUGGESTIONS_ENDPOINT: '/v2/suggestions',
    SEARCH_V2_API_URL: 'https://api.prod.carejourney.com/search-v2',
    PROFILE_API_URL: 'https://api.prod.carejourney.com/profiles',
    PROFILE_API_EXPLORER_PROVIDER_ENDPOINT: '/providers/explorer'
  }
};

const env = process.env.REACT_APP_STAGE || 'local';
export const PP_ENVIRONMENT: EnvironmentProps =
  PROVIDER_PERFORMANCE_ENVIRONMENT[env];
