import { Box, Badge, useTheme } from '@chakra-ui/react';
import { useHomeCtx } from 'context/HomeContext';
import { FC, Fragment } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { getAppLogoLinks } from './config';
import { NavLinksProps } from './types';
import { useAuth0 } from '@auth0/auth0-react';

const NavLinks: FC<NavLinksProps> = ({ appPage, toggleModal }) => {
  const { user } = useAuth0();
  const {
    mixpanel,
    featureFlags: { hideGlossary, useArcadiaStyling }
  } = useHomeCtx();
  const theme = useTheme();
  const route = useRouteMatch();
  const elements = getAppLogoLinks(appPage, user);

  const onClick = (): void => {
    mixpanel.track('Glossary opened');
    toggleModal();
  };

  const shouldShowGlossary = route.path !== '/home';

  return (
    <>
      {elements.map((element, idx) => {
        if ('logo' in element) {
          return <Fragment key={idx}>{element.logo}</Fragment>;
        }

        const { $textColor, $isActiveTextColor } = element.props;

        const isInternalLink = element.url.startsWith('/');

        return (
          <Box
            as={isInternalLink ? NavLink : 'a'}
            {...(isInternalLink
              ? { activeClassName: element.isActive, to: element.url }
              : { href: element.url })}
            position='relative'
            textDecoration='none'
            cursor='pointer'
            textAlign='center'
            fontSize='14px'
            marginTop={useArcadiaStyling ? '0px' : '-7px'}
            {...(element.isActive
              ? {
                  color: $isActiveTextColor || theme.colors?.mediumGrey,
                  fontFamily: useArcadiaStyling
                    ? theme.fonts.interSemiBold
                    : theme.fonts.semiBold,
                  _after: {
                    content: '""',
                    position: 'absolute',
                    bottom: useArcadiaStyling ? '-20px' : '-10px',
                    left: 0,
                    right: 0,
                    background: $isActiveTextColor,
                    height: useArcadiaStyling ? '2px' : '5px',
                    borderRadius: '5px'
                  }
                }
              : {
                  color:
                    $textColor || useArcadiaStyling
                      ? theme.colors?.arcadiaMainGrey
                      : theme.colors?.mediumGrey,
                  fontFamily: useArcadiaStyling
                    ? theme.fonts.interRegular
                    : theme.fonts.regular
                })}
            data-testid='nav-links--container'
            key={element.label}
          >
            {element.label}
            {!!element.badge && (
              <Badge
                ml='5px'
                height='16px'
                fontSize='10px'
                variant='solid'
                padding='0px 7px'
                borderRadius='3px'
                color={element.badge.color}
                colorScheme={element.badge.colorScheme}
              >
                {element.badge.label}
              </Badge>
            )}
          </Box>
        );
      })}
      {!hideGlossary && shouldShowGlossary && (
        <Box
          position='relative'
          width='71px'
          textDecoration='none'
          cursor='pointer'
          textAlign='center'
          color={
            useArcadiaStyling
              ? theme.colors?.arcadiaMainGrey
              : theme.colors?.mediumGrey
          }
          fontFamily={
            useArcadiaStyling ? theme.fonts?.interRegular : theme.fonts.regular
          }
          onClick={onClick}
          fontSize='14px'
          data-testid='nav-links--glossary-modal-button'
        >
          Glossary
        </Box>
      )}
    </>
  );
};

export default NavLinks;
