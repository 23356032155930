import { EnvironmentProps } from 'cj-dataverse-ui';
import { HomeEnvironments } from './homeEnvironments';

const DATAVERSE_ENVIRONMENT = {
  local: {
    ...HomeEnvironments.local,
    DOMAIN: 'dev-zto14ngg.auth0.com',
    CLIENT_ID: 'C1XHi2mpzCn8MPHZZvxAJjnkvnZzOxBG',
    AUDIENCE: 'https://api.local.carejourney.com/',
    BASE_APP_URL: 'http://localhost:3000',
    ENVIRONMENT: 'local',
    LAUNCH_DARKLY_CLIENT_ID: '62a74c5cd369e413a0519df9',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '6caa612894a18c1fa35718d19f1ed7f7',
    MIXPANEL_API_HOST: 'https://api.mixpanel.com',
    DATAVERSE_API_URL: 'http://localhost:3003/dataverse',
    STRAPI_BASE_URL: 'http://localhost:1338'
  },
  dev: {
    ...HomeEnvironments.dev,
    DOMAIN: 'auth-dev.carejourney.com',
    CLIENT_ID: 'Z7gQeFheavA4DGfzAqJ7zvv5GTQ317fb',
    AUDIENCE: 'https://api.dev.carejourney.com/',
    BASE_APP_URL: '',
    ENVIRONMENT: 'dev',
    LAUNCH_DARKLY_CLIENT_ID: '',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '',
    MIXPANEL_API_HOST: '',
    DATAVERSE_API_URL: 'https://api.dev.carejourney.com/dataverse',
    STRAPI_BASE_URL: 'https://young-dinosaur-11d8d900f7.strapiapp.com'
  },
  tst: {
    ...HomeEnvironments.tst,
    DOMAIN: 'auth-tst.carejourney.com',
    CLIENT_ID: 'Z7gQeFheavA4DGfzAqJ7zvv5GTQ317fb',
    AUDIENCE: 'https://api.int.carejourney.com/',
    BASE_APP_URL: '',
    ENVIRONMENT: 'tst',
    LAUNCH_DARKLY_CLIENT_ID: '',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '',
    MIXPANEL_API_HOST: '',
    DATAVERSE_API_URL: 'https://api.tst.carejourney.com/dataverse',
    STRAPI_BASE_URL: 'https://young-dinosaur-11d8d900f7.strapiapp.com'
  },
  int: {
    ...HomeEnvironments.int,
    DOMAIN: 'auth-int.carejourney.com',
    CLIENT_ID: 'Z7gQeFheavA4DGfzAqJ7zvv5GTQ317fb',
    AUDIENCE: 'https://api.int.carejourney.com/',
    BASE_APP_URL: '',
    ENVIRONMENT: 'int',
    LAUNCH_DARKLY_CLIENT_ID: '',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '',
    MIXPANEL_API_HOST: '',
    DATAVERSE_API_URL: 'https://api.int.carejourney.com/dataverse',
    STRAPI_BASE_URL: 'https://young-dinosaur-11d8d900f7.strapiapp.com'
  },
  prod: {
    ...HomeEnvironments.prod,
    DOMAIN: 'auth-prod.carejourney.com',
    CLIENT_ID: 'Z7gQeFheavA4DGfzAqJ7zvv5GTQ317fb',
    AUDIENCE: 'https://api.prod.carejourney.com/',
    BASE_APP_URL: '',
    ENVIRONMENT: 'prod',
    LAUNCH_DARKLY_CLIENT_ID: '',
    MIXPANEL_PUBLIC_PROJECT_TOKEN: '',
    MIXPANEL_API_HOST: '',
    DATAVERSE_API_URL: 'https://api.prod.carejourney.com/dataverse',
    STRAPI_BASE_URL: 'https://young-dinosaur-11d8d900f7.strapiapp.com'
  }
};

const env = process.env.REACT_APP_STAGE || 'local';
export const DV_ENVIRONMENT: EnvironmentProps = DATAVERSE_ENVIRONMENT[env];
