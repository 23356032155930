import { Glossary } from 'cj-common-react';
import { GlossaryModalProps } from './types';
import { FC } from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

const GlossaryModal: FC<GlossaryModalProps> = ({
  glossaryEntries,
  onClose,
  title,
  mixpanel,
  isOpen
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW='1084px'
        w='1084px'
        m='0 auto'
        data-testid='glossary-modal--container'
      >
        <Glossary
          title={title}
          onClose={onClose}
          mixpanel={mixpanel}
          glossaryEntries={glossaryEntries}
        />
      </ModalContent>
    </Modal>
  );
};
export default GlossaryModal;
